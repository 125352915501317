import breakpoints from './breakpoints';
import colors from './colors';
import shadows from './shadows';

const foundations = {
	breakpoints,
	colors,
	shadows,
};

export default foundations