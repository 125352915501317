import React from "react";
import { Grid, Box, Text, Heading, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

// Images
import homepageImage from "../../images/homepage.jpg";
import accessImage from "../../images/access_your_skills.jpg"
import careersImage from "../../images/explore_careers.jpg"
import skillsImage from "../../images/explore_new_skills.jpg"
import marketImage from "../../images/job_market.jpg"

const Card = ({ title, description, image, link, label}) => {
   return (
      <Grid templateRows="max-content 1fr" height="100%" gap={6}>
         <Heading as="h3" fontSize={{ base: "2xl", md: "2xl" }} textAlign="center">
            {title}
         </Heading>
         <Link to={link}>
            <Grid
               height="100%"
               borderWidth="4px"
               borderColor="gray.400"
               rounded="xl"
               templateRows="max-content 1fr"
               bg="white"
               overflow="hidden"
               boxShadow="md"
               transition="all 0.3s"
               _hover={{
                  boxShadow: "xl"
               }}
            >
               <Image aria-label={label} src={image} objectFit="cover" height="13rem" width="100%" borderBottom="4px solid" borderColor="gray.400" />
               <Text alignSelf="center" padding="1.4rem" fontSize={{ base: "1.4rem", xl: "1.6rem" }} textAlign="center" >{description}</Text>
            </Grid>
         </Link>
      </Grid>
   )
}



const Home = () => {
   return (
      <Box minHeight="calc(100vh - 13rem)" height="max-content">
         <Grid templateRows="max-content 1fr">
            <Grid
               bgImage={`url(${homepageImage})`}
               backgroundPosition="center right"
               bgSize='cover'
               bgRepeat='no-repeat'
               position='relative'
               height="70vh"
               maxHeight="40rem"
               minHeight="20rem"
            >
               <Text
                  textAlign="start"
                  color='white'
                  
                  fontWeight='600'
                  fontSize={{ base: "2em", md: "2.6em", xl: "3em", xxl: "3.4em" }}
                  lineHeight='1'
                  position="absolute"
                  top="20%"
                  left="10%"
                  width={{ base: "50%", xl: "40%" }}
               >
                  Discover the career that you are made for.
               </Text>
            </Grid>
            <Grid px="4rem" py="6rem" gap="4rem" templateColumns={{ base: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }} autoRows="max-content">
               <Card label="Paper on clipboard and macbook"title="Assess Your Skills" description="Find out which skills you have and which skills you need." image={accessImage} link="/skills-assessment" />
               <Card label="Man writing on piece of paper beside two macbooks"title="Explore New Skills" description="Find out how and where to acquire new skills." image={skillsImage} link="/skills-explorer" />
               <Card label="Construction man sharpening tool" title="Explore Careers" description="Learn about career options and the skills they require." image={careersImage} link="/occupation-explorer" />
               <Card label="Typing on macbook" title="Job Market and Skills Analytics"  description="Obtain insights about job market and skills." image={marketImage} link="/dashboard" />
            </Grid>
         </Grid>
      </Box>


   );
}

export default Home;
