import React from "react";
import { Box, Stat, StatLabel, StatArrow, StatHelpText, StatNumber } from "@chakra-ui/react";
const ValueVsPrevious = (props) => {
   //  const indicColour = "text-danger";
   const valueDiff = props.value - props.vsValue;

   const type = valueDiff < 0 ? "decrease" : "increase";
   let num = Math.abs(valueDiff)
   num = +num.toFixed(2);
   return (
      <Box position='relative' p={4}>
         <Box position='absolute' top={0} right={0} pt={5}>
            {props.icon}
         </Box>
         <Stat>
            <StatLabel fontSize='lg'>{props.title}</StatLabel>
            <StatNumber>{props.value + props.unit}</StatNumber>
            <StatHelpText fontSize='md'>
               <StatArrow type={type} />
               {num + props.unit + props.vsText}
            </StatHelpText>
         </Stat>
      </Box>
   );
};

export default ValueVsPrevious;
