import React from 'react'
import { Grid, Text } from '@chakra-ui/react'

const Footer = () => {
  return(
    <Grid alignContent="center" height="3rem" as="footer" textAlign="center" color="white" fontSize="sm" bg='white'>
     {/* <Text>&copy; Possible Made Here</Text> */}
    </Grid>
  )
}

export default Footer
