import React, { useState } from "react";
import {
   Button,
   Flex,
   Grid,
   Heading,
   Textarea,
   Tag,
   TagCloseButton,
   TagLabel,
   Center,
   Modal,
   ModalOverlay,
   ModalContent,
   ModalFooter,
   ModalBody,
   useDisclosure,
} from "@chakra-ui/react";

import { GrUserWorker } from "react-icons/gr";
import { BsNewspaper, BsPencilSquare } from "react-icons/bs";
import { IoSchool } from "react-icons/io5";
import { Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import Autocomplete from "../../components/Autocomplete";

import { useSkills, useJobTitles } from "../../api";
import { Wrapper } from "../../components/Wrapper";
import { useUserContext } from "./../../contexts";

const StyledStep = ({ title, number, type, children, instructions }) => {
   return (
      <Grid rowGap={2}>
         <Heading pl={4} textAlign='start' size='sm'>
            STEP {number} - {instructions}
         </Heading>
         <Grid rowGap={4} border='1px solid' borderColor='gray.400' borderRadius='lg' shadow='lg' p={6} templateColumns='100%'>
            <Grid templateColumns={{ base: "max-content 100%", sm: "max-content max-content max-content" }} gap={2}>
               <Center>{type === "job" ? <GrUserWorker /> : type === "resume" ? <BsNewspaper /> : type === "education" ? <IoSchool /> : <BsPencilSquare />}</Center>
               <Heading size='md' justifySelf='center'>
                  {title}
               </Heading>
               {type === "education" ? (
                  <Tooltip
                     label='Please note that the education catalog on this platform is currently limited to Ontario college programs and Queens University undergraduate programs.'
                     fontSize='md'
                     bg='white'
                     color='alphablack'
                  >
                     <InfoIcon boxSize={5} color='blue.600' />
                  </Tooltip>
               ) : null}
            </Grid>
            {children}
         </Grid>
      </Grid>
   );
};

const SkillsGatherer = ({ showResults }) => {
   const { data: skills } = useSkills();
   const { data: jobTitles, isFetching: isFetchingJobTitles } = useJobTitles();

   const { userJobs, addJob, removeJob, userSkills, addSkills } = useUserContext();

   let autocompleteJobTitles = isFetchingJobTitles ? [] : jobTitles;
   const [resumeText, setResumeText] = useState("");

   const handleAddJob = (jobTitle) => {
      let job = jobTitles?.find((job) => job.name === jobTitle);
      let matchedSkills = [];
      if (job) {
         let jobSkills = Object.keys(job.filtered_skills).map((e) => e.toLowerCase());
         for (let skill of skills) {
            // Add any skills to matchedSkills array that match the skills in the job posting
            if (jobSkills.includes(skill["Skill-Keywords"].toLowerCase())) matchedSkills = [...matchedSkills, skill];
         }
         // Add job id to the matched skills so that the userSkills knows where the skill was added from
         matchedSkills = matchedSkills.map((e) => {
            return { ...e, job: job._id };
         });
         addSkills(matchedSkills, "job");
         addJob(job);
      }
   };

   const handleResumeSkills = () => {
      let resumeCleansed = resumeText.replace(/[^a-zA-Z ]/g, "").toLowerCase();
      let matchedSkills = [];
      for (let skill of skills) {
         // Add any skills to matchedSkills array that match the skills in the resume text
         if (resumeCleansed.includes(skill["Skill-Keywords"].toLowerCase())) matchedSkills = [...matchedSkills, skill];
      }
      addSkills(matchedSkills, "resume");
   };

   // Parses through the skill information and structures it for displaying in the list
   const addSkillToDisplay = (skill, list) => {
      index = list.map((e) => e.title).indexOf(skill["KST Level 2"]);
      if (index === -1) {
         list = [...list, { title: skill["KST Level 2"], children: [{ title: skill["Skill-Keywords"], _id: skill._id, from: skill.from }] }];
      } else {
         childIndex = list[index].children.map((e) => e._id).indexOf(skill._id);
         if (childIndex === -1) {
            let currSkill = list[index];
            list[index] = { ...currSkill, children: [...currSkill.children, { title: skill["Skill-Keywords"], _id: skill._id, from: skill.from }] };
         }
      }
      return list;
   };

   const handleClearAll = () => {
      window.location.reload();
   };

   let displaySkills = [];
   let displayKnowledge = [];
   let displayTools = [];
   let index;
   let childIndex;

   for (let skill of userSkills) {
      switch (skill["KST Level 1"]) {
         case "Skills":
            displaySkills = addSkillToDisplay(skill, displaySkills);
            break;
         case "Knowledge":
            displayKnowledge = addSkillToDisplay(skill, displayKnowledge);
            break;
         case "Tools_and_Technologies":
            displayTools = addSkillToDisplay(skill, displayTools);
            break;
         default:
            break;
      }
   }
   let totalSkills = userSkills.length;

   const handleClick = () => {
      handleResumeSkills();
      showResults();
   };

   const GenerateReport = () => {
      const { isOpen, onOpen, onClose } = useDisclosure();

      return (
         <>
            <Button justifySelf='center' onClick={totalSkills > 20 && resumeText ? handleClick : onOpen}>
               Generate Report
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
               <ModalOverlay />
               <ModalContent>
                  {totalSkills < 20 && !resumeText ? (
                     <ModalBody>Please input your resume</ModalBody>
                  ) : !resumeText ? (
                     <ModalBody>Please input your resume</ModalBody>
                  ) : totalSkills < 20 ? (
                     <ModalBody>You have {totalSkills} skills which is less than 20</ModalBody>
                  ) : (
                     <ModalBody>Please input your resume</ModalBody>
                  )}

                  <ModalFooter>
                     <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Add More Skills
                     </Button>
                     {resumeText ? (
                        <Button variant='ghost' onClick={handleClick}>
                           Continue
                        </Button>
                     ) : (
                        <Button variant='ghost' onClick={handleClick}></Button>
                     )}
                  </ModalFooter>
               </ModalContent>
            </Modal>
         </>
      );
   };

   return (
      <Wrapper py={8} px={10} textAlign='center'>
         <Grid alignContent='start' p={4} rowGap={6} templateColumns='100%'>
            <Grid justifySelf='center' templateColumns='100%' gap={2}>
               <Heading size='lg' py={4}>
                  Skills Assessment
               </Heading>
               <Heading textAlign='center' size='sm'>
                  This tool matches you to jobs and occupations that align with your job title and skills in your resume.
               </Heading>
               <Button justifySelf='center' onClick={handleClearAll}>
                  Clear All
               </Button>
            </Grid>

            <StyledStep number={1} title='Job History' type='job' instructions='Please share a job title that demonstrates most of your skills.'>
               <Autocomplete
                  placeholder='Input your previous job title'
                  showButton
                  clearOnSubmit
                  inputLabel='downshift-1-input'
                  stackLabel='downshift-1-menu'
                  button={{ colorScheme: "gray", text: "Add Job Skills" }}
                  items={autocompleteJobTitles}
                  handleSubmit={(item) => handleAddJob(item)}
                  itemToString={(item) => item["name"]}
                  searchIcon
                  borderRadius='md'
               />
               <Flex flexWrap='wrap'>
                  {userJobs
                     .sort((a, b) => a.name.localeCompare(b.name))
                     .map((job, index) => (
                        <Tag key={index} mr={2} mb={2}>
                           <TagLabel whiteSpace='wrap'>{job.name}</TagLabel>
                           <TagCloseButton onClick={() => removeJob(index)} />
                        </Tag>
                     ))}
               </Flex>
            </StyledStep>

            <StyledStep number={2} title='Other Skills from Resume' type='resume' instructions='Please  paste your resume below.'>
               <Grid gap={4} template-columns='100%'>
                  <Textarea
                     aria-labelledby='downshift-1-menu'
                     placeholder='Paste skills from your resume here'
                     resize='none'
                     rows={12}
                     value={resumeText}
                     onChange={(e) => setResumeText(e.target.value)}
                  />
               </Grid>
               <Flex flexWrap='wrap'></Flex>
            </StyledStep>
         </Grid>

         <Grid position='sticky' top='0' right='0' alignSelf='start' p={4} gap={4}>
            <Heading pl={4} textAlign='start' size='sm'>
               STEP 3 - Please generate report below.
            </Heading>
            <Grid p={2} border='1px solid' borderColor='gray.400' borderRadius='lg' shadow='lg' rowGap={4} py={4} template-columns='max-content'>
               <GenerateReport />
            </Grid>
         </Grid>
      </Wrapper>
   );
};

export default SkillsGatherer;
