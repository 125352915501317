import React, { useState } from "react";
import { useUserContext } from "../contexts";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Grid,
    Heading,
    Text,
    Center,
    HStack,
    Button,
  } from "@chakra-ui/react";

import { GrUserWorker } from 'react-icons/gr';
import { BsNewspaper, BsPencilSquare, BsTrashFill, BsFillExclamationCircleFill, BsFillPlusCircleFill } from "react-icons/bs";
import { IoSchool, IoInformation } from "react-icons/io5";
import { Tooltip } from "@chakra-ui/react"
import { InfoIcon } from '@chakra-ui/icons'
import { useSkills } from "../api";


export const SkillList = ({ title, list, number, flag }) => {
    const { removeSkill, addSkills } = useUserContext();
    const [show, setShow] = useState(false);
    const { data: skills, isFetching: isFetchingSkills } = useSkills();

    const handleAddSkill = (skill) => {
      let newSkill = skills.filter(skillItem => skillItem["Skill-Keywords"].toLowerCase() === skill.toLowerCase())?.[0]
      if (newSkill) addSkills([newSkill], "bank");
    }
    const handleToggle = () => setShow(!show);
    let params = show ? {index:[...Array(list.length).keys()]} : {}

    return (
      <Grid rowGap={4} p={2} width="100%">
      <Grid flex="1" templateColumns="max-content max-content" justifySelf="Top">
        <Heading as="h2" mr="2" size="md" textAlign="start">{title}</Heading>
      {flag ?
        <Tooltip label="If you have these skills, click the green “Add” icon to include them in your skills panel." fontSize="md" bg="white" color="alphablack">
          <InfoIcon boxSize={5} color="blue.600"/>
        </Tooltip>
          : null }
      </Grid>
        <Grid templateColumns="max-content 1fr">
          <Button variant="link" size="sm" color="blue.600" onClick={handleToggle}>
            {show ? "Collapse" : "Expand"} All</Button>
          <Text fontWeight="bold" textAlign="end">{number}</Text>
        </Grid>
        <Accordion allowMultiple allowToggle {...params}>
          {list.sort((a, b) => a.title.localeCompare(b.title)).map((skillItem, index) => (
              <AccordionItem key={index}>
                {({ isExpanded }) => (
                <>
                  <AccordionButton bg={isExpanded ? "gray.300" : "unset"} _hover={{ bg: "gray.200"}}>
                    <Grid templateColumns="1fr max-content" width="100%">
                      <Text textAlign="left">
                        {skillItem.title.replace(/_/g, ' ')}
                      </Text>
                      <AccordionIcon />
                    </Grid>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Grid justifyItems="start" width="100%" gap={2}>
                      { skillItem.children.sort((a, b) => a.title.localeCompare(b.title)).map((skill, index) => (
                        <Grid width="100%" templateColumns="1fr max-content max-content" alignContent="start" key={index} gap={2} textAlign="left">
                          <Text>{ skill.title }</Text>
                          <HStack mr={2}>
                            {skill.from.map((from, index) => {
                              return (
                                <Center key={index}>
                                { from === "missing" ? <BsFillExclamationCircleFill/> :
                                  from === "job" ? <GrUserWorker /> :
                                  from === "resume" ? <BsNewspaper />  :
                                  from === "education" ? <IoSchool /> :
                                  <BsPencilSquare /> }
                              </Center>
                            )})}
                          </HStack>
                          {skill.from[0] !== "missing" ?
                          <Center onClick={() => removeSkill(skill._id)} _hover={{ cursor: "pointer" }}>
                            <BsTrashFill fill="#8b0000" />
                          </Center>
                          :
                          <Center onClick={() => handleAddSkill(skill.title)} _hover={{ cursor: "pointer" }} title="Add to skills">
                          <BsFillPlusCircleFill fill="#009933" />
                        </Center>}
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionPanel>
                </>)}
              </AccordionItem>
            ))}
        </Accordion>
      </Grid>
    )
  }
