import React from "react";
import { Box } from "@chakra-ui/react";

const Card = ({ children, ...props }) => {
   return (
      <Box px='4' py='5' rounded='lg' height='max-content' shadow='lg' {...props}>
         {children}
      </Box>
   );
};

export default Card;
