export default {
  type: "FeatureCollection",
  name: "canada_provinces",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        featurecla: "Admin-1 scale rank",
        scalerank: 2,
        adm1_code: "CAN-682",
        diss_me: 682,
        iso_3166_2: "CA-ON",
        wikipedia: "http://en.wikipedia.org/wiki/Ontario",
        iso_a2: "CA",
        adm0_sr: 1,
        name: "Ontario",
        name_alt: "Upper Canada",
        name_local: null,
        type: "Province",
        type_en: "Province",
        code_local: null,
        code_hasc: "CA.ON",
        note: null,
        hasc_maybe: null,
        region: "Eastern Canada",
        region_cod: null,
        provnum_ne: 7,
        gadm_level: 1,
        check_me: 20,
        datarank: 2,
        abbrev: "Ont.",
        postal: "ON",
        area_sqkm: 0,
        sameascity: -99,
        labelrank: 2,
        name_len: 7,
        mapcolor9: 2,
        mapcolor13: 2,
        fips: "CA08",
        fips_alt: null,
        woe_id: 2344922,
        woe_label: "Ontario, CA, Canada",
        woe_name: "Ontario",
        latitude: 50.5244,
        longitude: -84.7943,
        sov_a3: "CAN",
        adm0_a3: "CAN",
        adm0_label: 2,
        admin: "Canada",
        geonunit: "Canada",
        gu_a3: "CAN",
        gn_id: 6093943,
        gn_name: "Ontario",
        gns_id: -570663,
        gns_name: "Ontario, Province d'",
        gn_level: 1,
        gn_region: null,
        gn_a1_code: "CA.08",
        region_sub: "Ontario",
        sub_code: null,
        gns_level: 1,
        gns_lang: "fra",
        gns_adm1: "CA08",
        gns_region: null,
        min_label: 3.5,
        max_label: 7.5,
        min_zoom: 2.0,
        wikidataid: "Q1904",
        name_ar: "أونتاريو",
        name_bn: "অন্টারিও",
        name_de: "Ontario",
        name_en: "Ontario",
        name_es: "Ontario",
        name_fr: "Ontario",
        name_el: "Οντάριο",
        name_hi: "ओण्टारियो",
        name_hu: "Ontario",
        name_id: "Ontario",
        name_it: "Ontario",
        name_ja: "オンタリオ州",
        name_ko: "온타리오 주",
        name_nl: "Ontario",
        name_pl: "Ontario",
        name_pt: "Ontário",
        name_ru: "Онтарио",
        name_sv: "Ontario",
        name_tr: "Ontario",
        name_vi: "Ontario",
        name_zh: "安大略",
        ne_id: 1159309687,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-76.444042, 44.0980192],
              [-76.3561514, 44.1295701],
              [-76.3396719, 44.1965598],
              [-76.2298086, 44.204436],
              [-76.1583975, 44.2713407],
              [-75.8837393, 44.3735185],
              [-75.6695059, 44.5851652],
              [-75.4332998, 44.7531496],
              [-75.1586416, 44.9050865],
              [-74.8455313, 45.0139224],
              [-74.6807363, 44.998387],
              [-74.3456533, 45.1806612],
              [-74.4829824, 45.2812439],
              [-74.3895986, 45.5473118],
              [-74.3895986, 45.5473118],
              [-74.7301748, 45.6434038],
              [-74.9334219, 45.6472441],
              [-75.0872305, 45.5665433],
              [-75.1970938, 45.5896125],
              [-75.3124502, 45.5396173],
              [-75.4278066, 45.5203765],
              [-75.5871084, 45.4626147],
              [-75.7573965, 45.4125065],
              [-75.8397939, 45.3700725],
              [-75.9002188, 45.4125065],
              [-75.9606436, 45.4664673],
              [-76.0485342, 45.5088289],
              [-76.1638906, 45.5126784],
              [-76.2407949, 45.5011291],
              [-76.2572744, 45.4549086],
              [-76.3067129, 45.4433475],
              [-76.411083, 45.4934283],
              [-76.6582754, 45.5396173],
              [-76.6637686, 45.6011436],
              [-76.713207, 45.6357225],
              [-76.6912344, 45.7124882],
              [-76.7736318, 45.7316632],
              [-76.746166, 45.8389214],
              [-76.8340566, 45.9039418],
              [-76.9219473, 45.8886498],
              [-76.9219473, 45.8044681],
              [-76.9988516, 45.8006386],
              [-77.1856191, 45.8542272],
              [-77.2735098, 46.0108684],
              [-77.4987295, 46.1252037],
              [-77.7569082, 46.1023556],
              [-77.8447988, 45.953612],
              [-77.5097158, 45.926872],
              [-77.3833731, 45.7354974],
              [-77.8393057, 45.6241986],
              [-77.784374, 45.4587618],
              [-78.2348135, 45.358494],
              [-78.3886221, 45.5857683],
              [-79.0203359, 45.4240738],
              [-79.0972402, 45.5396173],
              [-79.6740225, 45.358494],
              [-79.6135977, 45.2580483],
              [-80.0365713, 45.1225518],

              [-80.0265713, 45.1225518],
              [-79.6035977, 45.2580483],
              [-79.6640225, 45.358494],
              [-79.0872402, 45.5396173],
              [-79.0103359, 45.4240738],
              [-78.3786221, 45.5857683],
              [-78.2248135, 45.358494],
              [-77.774374, 45.4587618],
              [-77.8293057, 45.6241986],
              [-77.3733731, 45.7354974],
              [-77.4997158, 45.926872],
              [-77.8347988, 45.953612],
              [-77.7469082, 46.1023556],
              [-77.4887295, 46.1252037],
              [-77.2635098, 46.0108684],
              [-77.1756191, 45.8542272],
              [-76.9888516, 45.8006386],
              [-76.9119473, 45.8044681],
              [-76.9119473, 45.8886498],
              [-76.8240566, 45.9039418],
              [-76.736166, 45.8389214],
              [-76.7636318, 45.7316632],
              [-76.6812344, 45.7124882],
              [-76.703207, 45.6357225],
              [-76.6537686, 45.6011436],
              [-76.6482754, 45.5396173],
              [-76.401083, 45.4934283],
              [-76.2967129, 45.4433475],
              [-76.2472744, 45.4549086],
              [-76.2307949, 45.5011291],
              [-76.1538906, 45.5126784],
              [-76.0385342, 45.5088289],
              [-75.9506436, 45.4664673],
              [-75.8902188, 45.4125065],
              [-75.8297939, 45.3700725],
              [-75.7473965, 45.4125065],
              [-75.5771084, 45.4626147],
              [-75.4178066, 45.5203765],
              [-75.3024502, 45.5396173],
              [-75.1870938, 45.5896125],
              [-75.0772305, 45.5665433],
              [-74.9234219, 45.6472441],
              [-74.7201748, 45.6434038],
              [-74.3795986, 45.5473118],
              [-74.3795986, 45.5473118],
              [-74.4729824, 45.2812439],
              [-74.3356533, 45.1806612],
              [-74.6707363, 44.998387],
              [-74.8355313, 45.0139224],
              [-75.1486416, 44.9050865],
              [-75.4232998, 44.7531496],
              [-75.6595059, 44.5851652],
              [-75.8737393, 44.3735185],
              [-76.1483975, 44.2713407],
              [-76.2198086, 44.204436],
              [-76.3296719, 44.1965598],
              [-76.3461514, 44.1295701],
              [-76.434042, 44.0980192],

              [-76.444042, 44.0980192],

              //   [-78.2952383, 43.9321005],
              //   [-77.9381826, 43.9597858],
              //   [-77.6800039, 44.0111673],
              //   [-77.5976064, 43.9835059],
              //   [-77.5261953, 43.9123173],
              //   [-77.3778799, 43.9400119],
              //   [-77.3229482, 43.9004443],
              //   [-77.1581533, 43.8251934],
              //   [-76.9658926, 43.8766911],
              //   [-76.8230703, 43.9479223],
              //   [-77.0043447, 43.9518771],
              //   [-76.8230703, 44.0861832],
              //   [-76.6912344, 44.0980192],
              //   [-76.6253164, 44.1650447],
            ],
            [
              //Segment 2

              [-79.8552969, 44.862273],
              [-79.6135977, 44.9284258],
              [-79.4378164, 44.862273],
              [-79.2840078, 44.7882475],
              [-79.1466787, 44.4519957],
              [-79.0313223, 44.3145915],
              [-78.5973623, 44.0940741],
              [-78.4820059, 43.8885689],
              [-78.4720059, 43.8885689],

              [-78.4820059, 43.8885689],
              [-78.4920059, 43.8885689],
              [-78.6073623, 44.0940741],
              [-79.0413223, 44.3145915],
              [-79.1566787, 44.4519957],
              [-79.2940078, 44.7882475],
              [-79.4478164, 44.862273],
              [-79.6235977, 44.9284258],
              [-79.8652969, 44.862273],

              [-79.8552969, 44.862273],
            ],
          ],
        ],
      },
    },
  ],
};
