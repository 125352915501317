import map_style from "./map_style.jsx";
import canada_provinces from "./canada_provinces.js";

const mapConst = {
  provinceHeatColorLevel0: "#f5f5f5",
  provinceHeatColorLevel1: "#c4e1ff",
  provinceHeatColorLevel2: "#89c2ff",
  provinceHeatColorLevel3: "#4ea4ff",
  provinceHeatColorLevel4: "#1485ff",

  opacity: 0.75,

  markerHeatColorLevel0: "#ffff00",
  markerHeatColorLevel0_1: "#ffe700",
  markerHeatColorLevel1: "#ffce00",
  markerHeatColorLevel1_2: "#ffb400",
  markerHeatColorLevel2: "#ff9a00",
  markerHeatColorLevel2_3: "#ff7a00",
  markerHeatColorLevel3: "#ff5a00",
  markerHeatColorLevel3_4: "#ff2d00",
  markerHeatColorLevel4: "#ff0000",
};

const mapPoly = (map, maps) => {
  map.setOptions({ styles: map_style });
  map.data.addGeoJson(canada_provinces);
  map.data.setStyle((feature) => {
    let colour = mapConst.provinceHeatColorLevel4;

    return {
      fillColor: "#a9a9a9",
      strokeColor: "#a9a9a9",
      strokeWeight: 5,
      fillOpacity: 1,
      clickable: false,
    };
  });
};

export default mapPoly;
