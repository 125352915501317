import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";

const PieGraph = (props) => {
  
  let labels = props.data.labels;
  let concatLabels = [];
  for (let label of props.data.labels) {
    concatLabels.push(label);
  }
  const [displayedLabels, setDisplayedLabels] = useState([...concatLabels]);



  // const handleHover = ( item ) => {
  //   // let newLabels=concatLabels;
  //   // newLabels[displayedLabels.indexOf(item.text)] =
  //   //   labels[displayedLabels.indexOf(item.text)];
  
  //   // setDisplayedLabels(concatLabels);
  //   props.handleToolTip(true, 'test');
  // }
  


  return ( labels.length>0 ?
    <Doughnut
      data={{
        labels: displayedLabels,
        datasets: [
          {
            label: props.title,
            data: props.data.data,
            backgroundColor: props.colors,
            borderColor: props.colors,
            borderWidth: 1,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        tooltips:{
          enabled:true,
        },
        legend: {
         
          onClick: (e) => e.stopPropagation(),
          // onHover: (e,item) => handleHover(item),
         
          position: "top",
          align: 'start',
          labels: {
            boxWidth: 10,
            fontSize: 16,
            fontColor: "rgba(0,0,0,1)",
          },
        },
      }}
    /> : <h1>No Information Available</h1>
  );
};

export default PieGraph;
