import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
   sm: "40em",
   md: "52em",
   lg: "64em",
   xl: "80em",
   xxl: "100em",
});

export default breakpoints;
