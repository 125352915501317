import React, { useEffect, useState } from "react";
import {
  Accordion,
  Box,
  Button,
  Flex,
  Grid,
  SimpleGrid,
  Select,
  Text,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Heading,
} from "@chakra-ui/react";

import {
  useCourses,
  useOccupations,
  useSortedJobPostings,
  useSortedOccupations,
  useOccupationsByText,
} from "../../api";
import { useUserContext } from "./../../contexts";

import Autocomplete from "./../../components/Autocomplete";
import SkillsReport from "./SkillsReport";
import JobPostingItem from "./JobPostingItem";
import OccupationItem from "./OccupationItem";
import Pagination from "./../../components/Pagination";
import MatchedOccupations from "./MatchedOccupations";

const SkillsResults = ({ showGatherer }) => {
  const { userSkills, sortedOccupations, sortOccupations } = useUserContext();
  const userSkillsNames = userSkills.map((a) => a["Skill-Keywords"]);
  const [searchText, setSearchText] = useState("");

  const { data: courses, isFetching: isFetchingCourses } = useCourses();
  const { data: occupations, isFetching: isFetchingOccupations } =
    useOccupations(userSkillsNames);
  const { data: sortedJobPostings, isFetching: isSortedJobPostings } =
    useSortedJobPostings(userSkillsNames);
  const { data: matchedOccupations, isFetching: isFetchingMatchedOccupations } =
    useSortedOccupations(userSkillsNames);
  const {
    data: matchedOccupationsByText,
    isFetching: isFetchingMatchedOccupationsByText,
  } = useOccupationsByText(userSkillsNames, searchText);
  const [numItemsToDisplay, setNumItemsToDisplay] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [filteredSortedPostings, setFilteredSortedPostings] = useState([]);
  const [filteredSortedOccupations, setFilteredSortedOccupations] = useState(
    []
  );
  const [filteredOccupations, setFilteredOccupations] = useState([]);
  const [sortFlag, setSortFlag] = useState(0);
  const [sortFlag1, setSortFlag1] = useState(0);
  const [sortFlag2, setSortFlag2] = useState(0);
  const [sortFlag3, setSortFlag3] = useState(0);
  const [otherCareersSearchVal, setOtherCareersSearchVal] = useState("All");
  const [occupationNumber, setOccupationNumber] = useState("all");
  const [salary, setSalary] = useState("all");
  const [location, setLocation] = useState("all");
  const [date, setDate] = useState("all");
  const [checkedNocs, setCheckedNocs] = useState(new Set());
  const [displayDetails, setDisplayDetails] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([1, 0, 0]);

  let numPagesJobPostings = !isSortedJobPostings
    ? Math.ceil(filteredSortedPostings.length / numItemsToDisplay)
    : 0;

  let uniqueLocations = !isSortedJobPostings
    ? sortedJobPostings
        .map((posting) => posting.city)
        .filter((v, i, a) => a.indexOf(v) === i) ///filter((element, index, array) => { ... } )
    : [];

  let numPagesSortedOccupations = sortedOccupations[1]
    ? Math.ceil(filteredSortedOccupations.length / numItemsToDisplay)
    : 0;

  let numPagesOccupations = !isFetchingOccupations
    ? Math.ceil(filteredOccupations.length / numItemsToDisplay)
    : 0;

  let startingIndex = (selectedPage - 1) * numItemsToDisplay;

  // const sortJobPostingsByMatchedNocs = (matchedOccupations, jobPostings) => {
  //   const nocCodes = matchedOccupations.map(noc => noc.NOC);
  //   const sortedJobPostings = jobPostings.sort((a, b) => {
  //     const indexA = nocCodes.indexOf(a.noc);
  //     const indexB = nocCodes.indexOf(b.noc);
  //     if (indexA === -1) return 1;
  //     if (indexB === -1) return -1;
  //     if (indexA !== indexB) return indexA - indexB;
  //     return b.matchingScore - a.matchingScore;
  //   });
  //   return sortedJobPostings;
  // }

  useEffect(() => {
    if (!isFetchingMatchedOccupations) {
      setCheckedNocs(
        new Set(
          matchedOccupations.slice(0, 1).map((occupation) => occupation.NOC)
        )
      );
    }
  }, [isFetchingMatchedOccupations]);

  if (!isSortedJobPostings && sortFlag === 0) { //Checking is sort flag is 0 so it is only executed once
    setFilteredSortedPostings(
      isFetchingMatchedOccupations
        ? sortedJobPostings
        : sortedJobPostings.filter((posting) => checkedNocs.has(posting.noc))
    );
    setSortFlag(1);
  }

  if (!isFetchingOccupations && sortFlag1 === 0) {//Checking is sort flag is 0 so it is only executed once
    setFilteredOccupations(occupations);
    setSortFlag1(1);
  }

  if (sortedOccupations[1] && sortFlag2 === 0) {//Checking is sort flag is 0 so it is only executed once
    console.log(checkedNocs);
    console.log(sortedOccupations.filter((occ) => checkedNocs.has(occ.NOC)));
    setFilteredSortedOccupations(
      isFetchingMatchedOccupations
        ? sortedOccupations
        : sortedOccupations.filter((occ) => checkedNocs.has(occ.NOC))
    );
    setSortFlag2(1);
  }

  if (!isFetchingMatchedOccupationsByText && sortFlag3 === 0) {//Checking is sort flag is 0 so it is only executed once
    setFilteredOccupations(matchedOccupationsByText);
    setSortFlag3(1);
  }

  const slicedCourses = !isFetchingCourses ? [...courses].slice(0, 5) : [];

  const removeFlagsAndBack = () => { //If the back button is pressed reset all values
    setSortFlag(0);
    setSortFlag1(0);
    setSortFlag2(0);
    setSortFlag3(0);
    sortOccupations([]);
    showGatherer();
  };

  const filterSortedPostings = (occNum, salaryTemp, loc, dateTemp) => {
    let temp;
    //If value isn't passed in get its current state
    if (occNum === "") {
      occNum = occupationNumber;
    } //get current occupationNumber vaule or else its null
    if (salaryTemp === "") {
      salaryTemp = salary;
    }
    if (loc === "") {
      loc = location;
    }
    if (dateTemp === "") {
      dateTemp = date;
    }
    if (loc != "all") { //Bunch of if else's depending on which filters are in use
      if (salaryTemp != "all") {
        if (occNum === "all") {
          temp = sortedJobPostings.filter(
            (posting) =>
              posting.city === loc &&
              (posting.wage_value * 1 >= salaryTemp * 1 ||  //Math depending if wage is hourly or yeary
                (posting.wage_unit === "hourly" &&
                  posting.wage_value * 2000 >= salary * 1))
          );
          if (dateTemp === "all") {
            setFilteredSortedPostings(temp);
          }
          if (dateTemp === "new") {
            setFilteredSortedPostings(
              [...temp].sort(
                (a, b) => new Date(b.post_date) - new Date(a.post_date)
              )
            );
          }
          if (dateTemp === "old") {
            setFilteredSortedPostings(
              [...temp].sort(
                (a, b) => new Date(a.post_date) - new Date(b.post_date)
              )
            );
          }
          setDate(dateTemp);
          setOccupationNumber(occNum);
          setSalary(salaryTemp);
          setLocation(loc);
          return;
        }
        temp = sortedJobPostings.filter(
          (posting) =>
            posting.noc[0] === occNum &&
            posting.city === loc &&
            (posting.wage_value * 1 >= salaryTemp * 1 ||
              (posting.wage_unit === "hourly" &&
                posting.wage_value * 2000 >= salaryTemp * 1))
        );
        if (dateTemp === "all") {
          setFilteredSortedPostings(temp);
        }
        if (dateTemp === "new") {
          setFilteredSortedPostings(
            [...temp].sort(
              (a, b) => new Date(b.post_date) - new Date(a.post_date)
            )
          );
        }
        if (dateTemp === "old") {
          setFilteredSortedPostings(
            [...temp].sort(
              (a, b) => new Date(a.post_date) - new Date(b.post_date)
            )
          );
        }
        setDate(dateTemp);
        setOccupationNumber(occNum);
        setSalary(salaryTemp);
        setLocation(loc);
        return;
      }
      if (occNum === "all") {
        temp = sortedJobPostings.filter((posting) => posting.city === loc);
        if (dateTemp === "all") {
          setFilteredSortedPostings(temp);
        }
        if (dateTemp === "new") {
          setFilteredSortedPostings(
            [...temp].sort(
              (a, b) => new Date(b.post_date) - new Date(a.post_date)
            )
          );
        }
        if (dateTemp === "old") {
          setFilteredSortedPostings(
            [...temp].sort(
              (a, b) => new Date(a.post_date) - new Date(b.post_date)
            )
          );
        }
        setDate(dateTemp);
        setOccupationNumber(occNum);
        setSalary(salaryTemp);
        setLocation(loc);
        return;
      }
      temp = sortedJobPostings.filter(
        (posting) => posting.noc[0] === occNum && posting.city === loc
      );
      if (dateTemp === "all") {
        setFilteredSortedPostings(temp);
      }
      if (dateTemp === "new") {
        setFilteredSortedPostings(
          [...temp].sort(
            (a, b) => new Date(b.post_date) - new Date(a.post_date)
          )
        );
      }
      if (dateTemp === "old") {
        setFilteredSortedPostings(
          [...temp].sort(
            (a, b) => new Date(a.post_date) - new Date(b.post_date)
          )
        );
      }
      setDate(dateTemp);
      setOccupationNumber(occNum);
      setSalary(salaryTemp);
      setLocation(loc);
      return;
    }
    if (salaryTemp != "all") {
      if (occNum === "all") {
        temp = sortedJobPostings.filter(
          (posting) =>
            posting.wage_value * 1 >= salaryTemp * 1 ||
            (posting.wage_unit === "hourly" &&
              posting.wage_value * 2000 >= salary * 1)
        );
        if (dateTemp === "all") {
          setFilteredSortedPostings(temp);
        }
        if (dateTemp === "new") {
          setFilteredSortedPostings(
            [...temp].sort(
              (a, b) => new Date(b.post_date) - new Date(a.post_date)
            )
          );
        }
        if (dateTemp === "old") {
          setFilteredSortedPostings(
            [...temp].sort(
              (a, b) => new Date(a.post_date) - new Date(b.post_date)
            )
          );
        }
        setDate(dateTemp);
        setOccupationNumber(occNum);
        setSalary(salaryTemp);
        setLocation(loc);
        return;
      }
      temp = sortedJobPostings.filter(
        (posting) =>
          posting.noc[0] === occNum &&
          (posting.wage_value * 1 >= salaryTemp * 1 ||
            (posting.wage_unit === "hourly" &&
              posting.wage_value * 2000 >= salaryTemp * 1))
      );
      if (dateTemp === "all") {
        setFilteredSortedPostings(temp);
      }
      if (dateTemp === "new") {
        setFilteredSortedPostings(
          [...temp].sort(
            (a, b) => new Date(b.post_date) - new Date(a.post_date)
          )
        );
      }
      if (dateTemp === "old") {
        setFilteredSortedPostings(
          [...temp].sort(
            (a, b) => new Date(a.post_date) - new Date(b.post_date)
          )
        );
      }
      setDate(dateTemp);
      setOccupationNumber(occNum);
      setSalary(salaryTemp);
      setLocation(loc);
      return;
    }
    if (occNum === "all") {
      temp = sortedJobPostings;
      if (dateTemp === "all") {
        setFilteredSortedPostings(temp);
      }
      if (dateTemp === "new") {
        setFilteredSortedPostings(
          [...temp].sort(
            (a, b) => new Date(b.post_date) - new Date(a.post_date)
          )
        );
      }
      if (dateTemp === "old") {
        setFilteredSortedPostings(
          [...temp].sort(
            (a, b) => new Date(a.post_date) - new Date(b.post_date)
          )
        );
      }
      setDate(dateTemp);
      setOccupationNumber(occNum);
      setSalary(salaryTemp);
      setLocation(loc);
      return;
    }
    temp = sortedJobPostings.filter((posting) => posting.noc[0] === occNum);
    if (dateTemp === "all") {
      setFilteredSortedPostings(temp);
    }
    if (dateTemp === "new") {
      setFilteredSortedPostings(
        [...temp].sort((a, b) => new Date(b.post_date) - new Date(a.post_date))
      );
    }
    if (dateTemp === "old") {
      setFilteredSortedPostings(
        [...temp].sort((a, b) => new Date(a.post_date) - new Date(b.post_date))
      );
    }
    setDate(dateTemp);
    setOccupationNumber(occNum);
    setSalary(salaryTemp);
    setLocation(loc);
    return;
  };

  const checkboxCallback = (value, noc, index) => {
    setCheckedNocs((prevChecked) => {
      let updatedCheckedNocs;
      if (value) {
        updatedCheckedNocs = prevChecked.add(noc);
      } else {
        // Cannot directly remove element from set so use a workaround
        updatedCheckedNocs = new Set(
          [...prevChecked].filter((item) => item !== noc)
        );
      }
      return updatedCheckedNocs;
    });
    setSortFlag(0);
    setSortFlag2(0);
    let newCheckedBoxes = [...checkedBoxes];
    value ? (newCheckedBoxes[index] = 1) : (newCheckedBoxes[index] = 0);
    setCheckedBoxes(newCheckedBoxes);
    console.log(checkedBoxes);
  };

  const handleNocSearch = (text) => {
    setSearchText(text);
    setSelectedPage(1);
    setOtherCareersSearchVal("All");
    setSortFlag3(0);
  };

  const filterSortedOccupations = (occNum) => {
    if (occNum === "all") {
      setFilteredSortedOccupations(sortedOccupations);
      return;
    }
    setFilteredSortedOccupations(
      sortedOccupations.filter((posting) => posting.NOC[0] === occNum)
    );
    return;
  };

  const filterOccupations = (occNum) => {
    if (occNum === "all") {
      setFilteredOccupations(occupations);
      return;
    }
    setFilteredOccupations(
      occupations.filter((posting) => posting.NOC[0] === occNum)
    );
    return;
  };

  return (
    <Box p={8}>
      <Flex justifyContent="space-between">
        <Button onClick={() => removeFlagsAndBack()} color="white" backgroundColor="#2D78BE">
          Back
        </Button>
      </Flex>
      <Box mb="2rem">
        <Heading as="h2" mb={2} size="lg" textAlign="center">
          Your Assessment Report
        </Heading>
        <Heading pl={4} textAlign="center" size="sm">
          Your skills have been matched to these jobs and occupations.
        </Heading>
      </Box>
      {displayDetails && (
        <SkillsReport
          sortedOccupations={matchedOccupations}
          isSortedOccupations={isFetchingMatchedOccupations}
          checkboxCallback={checkboxCallback}
          checkedBoxes={checkedBoxes}
          setCheckedBoxes={setCheckedBoxes}
        />
      )}
      <Grid templateColumns={!displayDetails ? { base: "100%", xl: "1fr max-content" } : { base: "100%", md: "" }} gap={1}>
        <Tabs
          isLazy
          size="lg"
          isFitted
          variant="enclosed-colored"
          onChange={() => {
            setSelectedPage(1);
            setFilteredOccupations(occupations);
          }}
        >
          <TabList >
            <Tab  fontSize="1.2em" fontWeight="550" _selected={{ color: "white", bg: "#2D78BE" }}>
              Explore Matched Jobs
            </Tab>
            <Tab fontSize="1.2em" fontWeight="550" _selected={{ color: "white", bg: "blue.500" }}>
              Explore Occupations
            </Tab>
            <Tab fontSize="1.2em" fontWeight="550" _selected={{ color: "white", bg: "blue.500" }}>
              Explore a New Career
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid pt={5} pb={4} alignItems="end" rowGap={4} columnGap={4} templateColumns={{ base: "100%", xl: "max-content 1fr" }}>
                <Pagination
                  numPages={numPagesJobPostings}
                  setSelectedPage={setSelectedPage}
                  selectedPage={selectedPage}
                />
                {!isSortedJobPostings ? (
                  <HStack

                    alignItems="center"
                    justifyContent="start"
                    spacing={4}
                    flexWrap="wrap"
                  >
                    <Grid
                      templateColumns={{ base: "100%", sm: "max-content 1fr" }}
                      alignItems="center"
                      columnGap="1rem"
                      mb="0.4rem"
                    >
                      <Text as="span">Occupations:</Text>
                      <Select
                        colorScheme="blue"
                        overflow="hidden"
                        w="15rem"
                        onChange={(e) => {
                          filterSortedPostings(e.target.value, "", "", "");
                        }}
                      >
                        <option value="all">Any Occupations</option>
                        <option value="0">Management Occupations</option>
                        <option value="1">
                          Business, finance and administration occupations
                        </option>
                        <option value="2">
                          Natural and applied sciences and related occupations
                        </option>
                        <option value="3">Health occupations</option>
                        <option value="4">
                          Occupations in education, law and social, community
                          and government services
                        </option>
                        <option value="5">
                          Occupations in art, culture, recreation and sport
                        </option>
                        <option value="6">Sales and service occupations</option>
                        <option value="7">
                          Trades, transport and equipment operators and related
                          occupations
                        </option>
                        <option value="8">
                          Natural resources, agriculture and related production
                          occupations
                        </option>
                        <option value="9">
                          Occupations in manufacturing and utilities
                        </option>
                      </Select>
                    </Grid>
                    <Select
                      colorScheme="blue"
                      w="15rem"
                      mb="0.4rem"
                      onChange={(e) => {
                        filterSortedPostings("", e.target.value, "", "");
                      }}
                    >
                      <option value="all">Any Salary</option>
                      <option value="25000">25k+</option>
                      <option value="50000">50k+</option>
                      <option value="75000">75k+</option>
                      <option value="100000">100k+</option>
                    </Select>
                    <Select
                      w="15rem"
                      mb="0.4rem"
                      colorScheme="blue"
                      onChange={(e) => {
                        filterSortedPostings("", "", e.target.value, "");
                      }}
                    >
                      <option value="all">Any Location</option>
                      {uniqueLocations.map((loc) => (
                        <option value={loc}>{loc}</option>
                      ))}
                    </Select>
                    <Select
                      w="15rem"
                      mb="0.4rem"
                      colorScheme="blue"
                      onChange={(e) => {
                        filterSortedPostings("", "", "", e.target.value);
                      }}
                    >
                      <option value="all">Any Post Date</option>
                      <option value="new">Newest First</option>
                      <option value="old">Oldest First</option>
                    </Select>
                    <Grid
                      templateColumns={{ base: "100%", sm: "max-content 1fr" }}
                      alignItems="center"
                      columnGap="1rem"
                      mb="0.4rem"
                    >
                      <Text as="span">Top Matches:</Text>
                      <Select
                        value={numItemsToDisplay.toString()}
                        w="15rem"
                        colorScheme="blue"
                        onChange={(e) => {
                          let numItems = +e.target.value;
                          setSelectedPage(
                            Math.floor(startingIndex / numItems) + 1
                          );
                          setNumItemsToDisplay(numItems);
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </Select>
                    </Grid>
                  </HStack>
                ) : null}
              </Grid>

              {isSortedJobPostings && (
                <Flex justify="center">
                  <Text as="span" mr={2}>
                    Matching Job Postings{" "}
                  </Text>{" "}
                  <Spinner size="lg" />
                </Flex>
              )}
              {!isSortedJobPostings ? (
                filteredSortedPostings[0] ? (
                  <Accordion allowToggle={true} pb={10}>
                    {filteredSortedPostings
                      .slice(startingIndex, startingIndex + numItemsToDisplay)
                      .map((posting, index) => (
                        <JobPostingItem
                          posting={posting}
                          courses={slicedCourses}
                          key={index}
                        />
                      ))}
                  </Accordion>
                ) : (
                  <Flex justify="center">
                    <Text as="span">No job postings matching your filters</Text>
                  </Flex>
                )
              ) : (
                ""
              )}
            </TabPanel>
            <TabPanel>
              <Grid pt={5} pb={4} alignItems="end" rowGap={4} columnGap={4} templateColumns={{ base: "100%", md: "max-content 1fr" }}>
                <Pagination
                  numPages={numPagesSortedOccupations}
                  setSelectedPage={setSelectedPage}
                  selectedPage={selectedPage}
                />
                {!isSortedJobPostings ? (
                  <HStack
                    alignItems="center"
                    justifyContent="start"
                    spacing={4}
                    flexWrap="wrap"
                  >
                    <Grid
                      templateColumns={{ base: "100%", sm: "max-content 1fr" }}
                      alignItems="center"
                      columnGap="1rem"
                      mb="0.4rem"
                    >
                      <Text as="span">Occupations</Text>
                      <Select
                        colorScheme="blue"
                        w="20rem"
                        overflow="hidden"
                        onChange={(e) => {
                          filterSortedOccupations(e.target.value);
                        }}
                      >
                        <option value="all">All</option>
                        <option value="0">Management Occupations</option>
                        <option value="1">
                          Business, finance and administration occupations
                        </option>
                        <option value="2">
                          Natural and applied sciences and related occupations
                        </option>
                        <option value="3">Health occupations</option>
                        <option value="4">
                          Occupations in education, law and social, community and
                          government services
                        </option>
                        <option value="5">
                          Occupations in art, culture, recreation and sport
                        </option>
                        <option value="6">Sales and service occupations</option>
                        <option value="7">
                          Trades, transport and equipment operators and related
                          occupations
                        </option>
                        <option value="8">
                          Natural resources, agriculture and related production
                          occupations
                        </option>
                        <option value="9">
                          Occupations in manufacturing and utilities
                        </option>
                      </Select>
                    </Grid>
                    <Grid
                      templateColumns={{ base: "100%", sm: "max-content 1fr" }}
                      alignItems="center"
                      columnGap="1rem"
                      mb="0.4rem"
                    >
                      <Text as="span">Top Matches:</Text>
                      <Select
                        value={numItemsToDisplay.toString()}
                        colorScheme="blue"
                        onChange={(e) => {
                          let numItems = +e.target.value;
                          setSelectedPage(
                            Math.floor(startingIndex / numItems) + 1
                          );
                          setNumItemsToDisplay(numItems);
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </Select>
                    </Grid>
                </HStack>
                ) : (
                  ""
                )}
              </Grid>
              <Accordion allowToggle={true} pb={10}>
                {filteredSortedOccupations
                  .slice(startingIndex, startingIndex + numItemsToDisplay)
                  .map((posting, index) => (
                    <OccupationItem
                      posting={posting}
                      courses={slicedCourses}
                      key={index}
                    />
                  ))}
              </Accordion>
            </TabPanel>
            <TabPanel>
              <Grid
                pt={5}
                pb={4}
                alignItems="center"
                templateColumns={{ base: "1fr", xxl: "1fr max-content" }}
                rowGap={4}
              >
                <Pagination
                  numPages={numPagesOccupations}
                  setSelectedPage={setSelectedPage}
                  selectedPage={selectedPage}
                />
                {!isSortedJobPostings ? (
                  <Grid
                    templateColumns={{ base: "1fr", lg: "1fr max-content max-content max-content max-content" }}
                    columnGap={4}
                    rowGap={4}
                  >
                    <Autocomplete
                      handleSubmit={handleNocSearch}
                      showButton
                      clearOnSubmit
                      items={occupations ? occupations : []}
                      itemToString={(item) => item.Title}
                      button={{ text: "Search" }}
                      placeholder="Search an occupation or skill"
                      border="1px solid"
                      borderColor="blue.400"
                    />
                      <Text as="span">Occupations</Text>
                      <Select
                        colorScheme="blue"
                        w="20rem"
                        overflow="hidden"
                        value={otherCareersSearchVal}
                        onChange={(e) => {
                          setOtherCareersSearchVal(e.target.value);
                          filterOccupations(e.target.value);
                        }}
                      >
                        <option value="all">All</option>
                        <option value="0">Management Occupations</option>
                        <option value="1">
                          Business, finance and administration occupations
                        </option>
                        <option value="2">
                          Natural and applied sciences and related occupations
                        </option>
                        <option value="3">Health occupations</option>
                        <option value="4">
                          Occupations in education, law and social, community
                          and government services
                        </option>
                        <option value="5">
                          Occupations in art, culture, recreation and sport
                        </option>
                        <option value="6">Sales and service occupations</option>
                        <option value="7">
                          Trades, transport and equipment operators and related
                          occupations
                        </option>
                        <option value="8">
                          Natural resources, agriculture and related production
                          occupations
                        </option>
                        <option value="9">
                          Occupations in manufacturing and utilities
                        </option>
                      </Select>
                      <Text as="span">Top Matches:</Text>
                      <Select
                        value={numItemsToDisplay.toString()}
                        colorScheme="blue"
                        onChange={(e) => {
                          let numItems = +e.target.value;
                          setSelectedPage(
                            Math.floor(startingIndex / numItems) + 1
                          );
                          setNumItemsToDisplay(numItems);
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </Select>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>

              {!isFetchingOccupations ? (
                <Accordion allowToggle={true} pb={10}>
                  {filteredOccupations
                    .slice(startingIndex, startingIndex + numItemsToDisplay)
                    .map((posting, index) => (
                      <OccupationItem
                        posting={posting}
                        courses={slicedCourses}
                        key={index}
                      />
                    ))}
                </Accordion>
              ) : (
                ""
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        {!displayDetails && (
          <MatchedOccupations
            sortedOccupations={matchedOccupations}
            isSortedOccupations={isFetchingMatchedOccupations}
            checkboxCallback={checkboxCallback}
            setDisplayDetails={setDisplayDetails}
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
          />
        )}
      </Grid>
    </Box>
  );
};

export default SkillsResults;
