import { useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const CityBarGraph = ({ data }) => {
   let { labels, totals } = data;

   let isNamesShort = useBreakpointValue({ base: true, xl: false }) 
   // Shorten the names of areas that end with "And Surrounding"  
   labels = isNamesShort ? [...labels].map(name => name.replace("And Surrounding ", "")) : labels
   
   return (
      <HorizontalBar
         data={{
            labels: labels,
            datasets: [
               {
                  label: " Please note that job postings are not integrated to this page, only statistics are provided",
                  data: totals,
                  backgroundColor: totals.map(() => "rgba(54, 162, 235, 0.2)"),
                  borderColor: totals.map(() => "rgba(54, 162, 235, 1)"),
                  borderWidth: 1,
               },
            ],
         }}
         options={{
            layout: {
               padding: {
                   top: 0,
                   left: 15,
                   right: 25,
                   bottom: 25
               }
           },
            tooltips: {
               yAlign:"bottom",
               titleFontSize: 16,
               bodyFontSize: 16,
               backgroundColor:'rgba(128, 128, 128, 1)'
            },
            maintainAspectRatio: false,
            legend: {
               display: false,
            },
            scales: {
               yAxes: [
                  {
                     ticks: {
                        fontSize: 16,
                        fontColor: "#010101",
                        beginAtZero: true,
                     },
                  },
               ],
               xAxes: [
                  {
                     ticks: {
                        fontSize: 16,
                        fontColor: "#010101",
                        beginAtZero: true,
                        precision: 0
                     },
                     scaleLabel: {
                        display: true,
                        labelString: "Jobs",
                        fontSize: 24,
                        fontColor: "#010101",
                     },
                     position: `top`
                  },
               ],
            },
         }}
      />
   );
};

export default CityBarGraph;
