import React from "react";
import { Button, HStack } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

const Pagination = ({ selectedPage, setSelectedPage, numPages }) => {

   // -1 is arrow forwards
   // -2 is arrow back

   let pageNumbers = [];
   if (numPages < 8) {
      for (let i = 1; i < numPages + 1; i++) {
         pageNumbers = [...pageNumbers, i];
      }
   } else if (selectedPage < 5) {
      pageNumbers = [1, 2, 3, 4, 5, -1, numPages];
   } else if (selectedPage > numPages - 4) {
      pageNumbers = [1, -2];
      for (let i = numPages - 4; i < numPages + 1; i++) {
         pageNumbers = [...pageNumbers, i];
      }
   } else {
      pageNumbers = [1, -2];
      for (let i = selectedPage - 1; i < selectedPage + 2; i++) {
         pageNumbers = [...pageNumbers, i];
      }
      pageNumbers = [...pageNumbers, -1, numPages];
   }

   return (
      <HStack>
         {pageNumbers.map((number) => {
            let isSelected = number === selectedPage;
            let hoverStyles = { bg: isSelected ? "blue.300" : "gray.400" };

            if (number === -1) {
               return (
                  <Button aria-label="move on to next page" width={{base: '2.5rem', lg: '3rem'}} height='3rem' p={0} bg='gray.200' _hover={hoverStyles} onClick={() => setSelectedPage(selectedPage + 1)}>
                     <ChevronRightIcon fontSize='1.8rem' />
                  </Button>
               );
            }

            if (number === -2) {
               return (
                  <Button aria-label="go back one page" width={{base: '2.5rem', lg: '3rem'}} height='3rem' p={0} bg='gray.200' _hover={hoverStyles} onClick={() => setSelectedPage(selectedPage - 1)}>
   
                     <ChevronLeftIcon fontSize='1.8rem' />
                  </Button>
               );
            }

            return (
               <Button
                  width={{base: '2.5rem', lg: '3rem'}}
                  height='3rem'
                  bg={isSelected ? "blue.300" : "gray.200"}
                  _hover={hoverStyles}
                  _focus={hoverStyles}
                  onClick={() => setSelectedPage(number)}
                  key={number}
               >
                  {number}
               </Button>
            );
         })}
      </HStack>
   );
};

export default Pagination;
