import React, { useState } from "react";
import { Box, Button, Grid, Heading, Text, UnorderedList, HStack, ListItem, Tag } from "@chakra-ui/react";

const NocListItem = ({ noc, isInList }) => {
   const [expandDesc, setExpandDesc] = useState(false);
   const [expandSkills, setExpandSkills] = useState(false);

   let desc = noc.Description;
   const isDescTooLong = desc?.length > 400;
   const description = isDescTooLong ? (expandDesc ? desc : `${desc?.substring(0, 400)}...`) : desc;

   let isSkillsTooLong = noc["Skill-Keywords"].length > 15;
   const skillIndex = isSkillsTooLong ? (expandSkills ? noc["Skill-Keywords"].length : 16) : noc["Skill-Keywords"].length;

   return (
      <Grid
         templateRows='max-content 1fr max-content'
         boxShadow={isInList ? "md" : null}
         border={isInList ? "1px" : null}
         borderColor={isInList ? "gray.200" : null}
         borderRadius='md'
         gap={4}
      >
         <Grid bg='gray.200' px={6} gap={6} minHeight='3.8rem' alignItems='center'>
            <Heading size='md'>
               {noc.NOC} - {noc.Title}
            </Heading>
         </Grid>
         <Grid gap={6} px={6} pb={2}>
            <Box>
               <Text as='span'>
                  <Text as='span' mr={2}>
                     {description}
                  </Text>
                  {isDescTooLong && (
                     <Button variant='wrapper' size='sm' color='#2D78BE' mb={1} onClick={() => setExpandDesc((prevValue) => !prevValue)}>
                        {expandDesc ? "Show Less" : "Show More"}
                     </Button>
                  )}
               </Text>
            </Box>
            <Box>
               <Text fontWeight='700' mb={3}>Job Titles</Text>
               <HStack wrap="wrap" spacing={0}>
                  {noc.IllustrativeExamples?.[0].slice(0, 10).map((title) => <Tag textTransform="capitalize" mb={2} mr={2}>{title}</Tag>)}
               </HStack>
            </Box>
            <Box>
               <Text fontWeight='700'>Main Duties</Text>
               <UnorderedList>
                  {noc.MainDuties?.[0].slice(1,noc.MainDuties?.[0].length).map((item, index) => (
                     <ListItem key={index} textTransform='capitalize' >
                       <p>{item}</p>
                     </ListItem>
                  ))}
               </UnorderedList>
            </Box>
            <Box>
               <Text fontWeight='700' mb={2}>Skills Required:</Text>
               <UnorderedList display='flex' flexFlow='row' flexWrap='wrap' width="90%">
                  {noc["Skill-Keywords"].slice(1, skillIndex).map((item, index) => (
                     <Tag key={index} textTransform="capitalize" mb={2} mr={2}>
                        {item}
                     </Tag>
                  ))}
               </UnorderedList>
               {isSkillsTooLong && (
                  <Button variant='wrapper' size='md' color='#2D78BE' mb={1} mt={3} onClick={() => setExpandSkills((prevValue) => !prevValue)}>
                     {expandSkills ? "Show Less Skills" : "Show All Skills"}
                  </Button>
               )}
            </Box>
         </Grid>
      </Grid>
   );
};

export default NocListItem;
