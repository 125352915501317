import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  HStack,
  Button,
  Flex,
  Spacer,
  Spinner,
  Tag,
  Grid,
  Checkbox
} from "@chakra-ui/react";
import { useUserContext } from "./../../contexts/UserContext";
import { SkillList} from "../../components/SkillsList";
import { IoInformation } from "react-icons/io5";
import { useOccupations, useSkills, useSortedOccupations } from "../../api";
import { Tooltip } from "@chakra-ui/react"
import { InfoIcon } from '@chakra-ui/icons'

const SkillsReportItem = ({
  title,
  items = [],
  missingSkills,
  occupationSelected,
}) => {
  const [fullMissingSkills, setFullMissingSkills] = useState([]);

  useEffect(() => {
    if (occupationSelected) {
      if (missingSkills.length > 10) {
        if (items.length >= 9) {
          setFullMissingSkills(missingSkills.slice(0, 3));
        } else {
          setFullMissingSkills(missingSkills.slice(0, 10));
        }
      } else {
        if (items.length >= 9) {
          setFullMissingSkills(missingSkills.slice(0, 3));
        } else {
          setFullMissingSkills(missingSkills);
        }
      }
    }
  }, [occupationSelected, missingSkills, items]);

  const showMoreButton =
    occupationSelected && fullMissingSkills.length < missingSkills.length;
  const showLessButton =
    occupationSelected &&
    items.length + fullMissingSkills.length > 10 &&
    fullMissingSkills.length > 3;

  function showMoreSkills() {
    if (missingSkills.length > fullMissingSkills.length) {
      if (fullMissingSkills.length + 10 <= missingSkills.length) {
        setFullMissingSkills(
          missingSkills.slice(0, fullMissingSkills.length + 10)
        );
      } else if (fullMissingSkills.length + 10 > missingSkills.length) {
        setFullMissingSkills(missingSkills);
      }
    }
  }

  function showLess() {
    if (fullMissingSkills.length < 10) {
      return;
    }
    if (fullMissingSkills.length + items.length > 9) {
      if (fullMissingSkills.length > 10) {
        setFullMissingSkills(
          missingSkills.slice(0, fullMissingSkills.length - 10)
        );
      } else if (fullMissingSkills.length <= 10) {
        setFullMissingSkills(missingSkills.slice(0, 10 - items.length));
      }
    }
  }

  return (
    <VStack
      minH="10rem"
      p={4}
      
      spacing={2}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      align="start"
      width="100%"
    >
      <>
        <Heading as="h4" size="sm">
          {title}
        </Heading>
        <VStack size="sm" align="start" spacing={1}>

          {items.map((item, index) => (
            <Tag
              key={index}
              fontSize="sm"
              textTransform="capitalize"
              isTruncated
            >
              {item}
            </Tag>
          ))}
          {occupationSelected &&
            fullMissingSkills.map((skill, index) => {
              return (
                <Tag
                  color="red.500"
                  key={index}
                  fontSize="sm"
                  textTransform="capitalize"
                  isTruncated
                >
                  {skill}
                </Tag>
              );
            })}
        </VStack>
      </>
      <Spacer />
      <Flex direction="row" w="100%">
        {showMoreButton && (
          <Button m={3} w="50%" onClick={() => showMoreSkills()}>
            Show More
          </Button>
        )}
        {showLessButton && (
          <Button m={3} w="50%" onClick={() => showLess()}>
            Show Less
          </Button>
        )}
      </Flex>
    </VStack>
  );
};

const MatchedOccupations = ({ sortedOccupations, isSortedOccupations, checkboxCallback, setDisplayDetails, checkedBoxes }) => {
  const { userSkills, sortOccupations } = useUserContext();
  const {data: occupations, isFetching: isFetchingOccupations} = useOccupations();
  const { data: skills, isFetching: isFetchingSkills } = useSkills();
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [sortFlag, setSortFlag] = useState(0);



  if (!isSortedOccupations && sortFlag === 0){
    setSelectedOccupation(sortedOccupations[0])
    sortOccupations(sortedOccupations);
    setSortFlag(1);
  }

  const addSkillToDisplay = (skill, list) => {
    index = list.map((e) => e.title).indexOf(skill["KST Level 2"])
    if (index === -1) {
      list = [...list, { title: skill["KST Level 2"], children: [{ title: skill["Skill-Keywords"], _id: skill._id, from: skill.from }]}]
    } else {
      childIndex = list[index].children.map(e => e._id).indexOf(skill._id);
      if (childIndex === -1) {
        let currSkill = list[index]
        list[index] = { ...currSkill, children: [...currSkill.children, { title: skill["Skill-Keywords"], _id: skill._id, from: skill.from } ]}
      }
    }
    return list
  }

  let displaySkills = [];
  let displayKnowledge = [];
  let displayTools = [];
  let index;
  let childIndex;

  for (let skill of userSkills) {
    switch (skill["KST Level 1"]) {
      case "Skills":
        displaySkills = addSkillToDisplay(skill, displaySkills)
        break;
      case "Knowledge":
        displayKnowledge = addSkillToDisplay(skill, displayKnowledge)
        break;
      case "Tools_and_Technologies":
        displayTools = addSkillToDisplay(skill, displayTools)
        break;
      default:
        break;
    }
  }

  let data = { Skills: [], Knowledge: [], Tools_and_Technologies: [] };

  for (let skill of userSkills) {
    data[skill["KST Level 1"]] = [
      ...data?.[skill["KST Level 1"]],
      skill["Skill-Keywords"],
    ];
  }

  const getMissingSkills = (input) => {
    let occupationFound = occupations.find(
      (occupations) => occupations.Title === selectedOccupation.Title
    );
    let skillsFound = occupationFound["Skill-Keywords"].map(
      (skill) =>
        (skill = skills.find(
          (fullSkill) => fullSkill["Skill-Keywords"] === skill
        ))
    );
    let missingSkills = skillsFound.filter(
      (skillFiltered) =>
        skillFiltered &&
          skillFiltered["KST Level 1"] === input &&
          !data[input].find(
            (userSkill) => userSkill === skillFiltered["Skill-Keywords"]
          )
    );
    let displayMissingSkills = [];
    for (let missingSkill of missingSkills) {
      missingSkill.from = ["missing"];
      displayMissingSkills = addSkillToDisplay(missingSkill, displayMissingSkills)
    }
    return displayMissingSkills
    };

  return (
    <SimpleGrid spacing={2}>
      {isFetchingOccupations ? (
        <Flex justify="center">
          <Text as="span" mr={2}>
            Fetching Occupations{" "}
          </Text>{" "}
          <Spinner size="lg" />
        </Flex>
      ) : (
        <>
          <VStack
            minH="10rem"
            p={4}
            spacing={2}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            align="start"
            width="100%"
            position="relative"
          >
            <Grid templateColumns={{base: "100%", sm: "max-content"}}>
              <Heading size="lg" mr="2">
                Matched Occupations
              </Heading>
              <Text fontSize="15px">
                * Please select occupations that only align with your interests.
              </Text>
            </Grid>

            {isSortedOccupations ? (
              <Flex justify="center">
                <Text as="span" mr={2}>
                  Matching Occupations{" "}
                </Text>{" "}
                <Spinner size="lg" />
              </Flex>
            ) : (
              
              <VStack size="sm" align="start" spacing={2} width="100%">
                {sortedOccupations.slice(0, 3).map((occupation, index) => {
                  return (
                    <HStack
                      spacing={2}
                      width="100%"
                      p={4}
                      bg={
                        selectedOccupation === occupation ? "gray.300" : "none"
                      }
                      onClick={() => setSelectedOccupation(occupation)}
                      rounded="md"
                      cursor="pointer"
                      key={index}
                      _hover={{
                        bg:
                          selectedOccupation === occupation
                            ? "gray.300"
                            : "gray.100",
                      }}
                    >
                      {/* <Box fontSize="sm">{(occupation.matchingScore * 100).toFixed(0)}% Match ({occupation.hardSkillCount}/{occupation.totalHardSkillCount})</Box> */}
                      <Box fontSize="sm" width="80%" p="0.4rem">
                        {occupation.NOC} - {occupation.Title}
                      </Box>
                      <Checkbox

                        defaultIsChecked={
                          checkedBoxes[index] === 1 ? true : false
                        }
                        onChange={(e) =>
                          checkboxCallback(e.target.checked, occupation.NOC, index)
                        }
                      />
                    </HStack>
                  );
                })}
              </VStack>
            )}
          </VStack>
          <Button onClick={() => setDisplayDetails(true)}>
              View how your skills were matched
            </Button>
            
        </>
      )}
    </SimpleGrid>
  );
};

export default MatchedOccupations;
