import React, { useState } from "react";
import dayjs from "dayjs";
import * as he from 'he'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Text,
  HStack,
  Spinner,
  Flex,
  useControllableState,
} from "@chakra-ui/react";
import { CheckIcon, TimeIcon } from "@chakra-ui/icons";
import { useUserContext } from "./../../contexts/UserContext";
import { useSkills, useJobTitles, useCourseMatches } from "../../api";
import ProgramAccordionItem from "./../CareerExplorer/ProgramAccordionItem";


const SkillItem = ({ isMissing, label }) => {
  return (
    <Center
      p={2}
      bg={`${isMissing ? "red" : "green"}.100`}
      color={`${isMissing ? "red" : "green"}.900`}
      fontSize="sm"
      fontWeight="600"
      borderRadius="md"
      textAlign="center"
    >
      {label}
    </Center>
  );
};

const SkillItemDropdown = ({ isMissing, title, labels }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          bg={`${isMissing ? "red" : "green"}.100`}
          color={`${isMissing ? "red" : "green"}.900`}
          _hover={{ background: `${isMissing ? "red" : "green"}.100` }}
          fontSize="sm"
          fontWeight="600"
          borderRadius="md"
          mb={1}
        >
          <Box flex="1" textAlign="left">
            {title.replace(/_/g, " ")}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={0}>
        {isMissing
          ? labels.map((label) => (
              <SkillItem isMissing label={label}></SkillItem>
            ))
          : labels.map((label) => <SkillItem label={label}></SkillItem>)}
      </AccordionPanel>
    </AccordionItem>
  );
};

const JobPostingItem = ({ posting }) => {
  const { isPostingSaved, savePosting, unsavePosting, userSkills } =
    useUserContext();
  const {
    employer,
    city,
    wage_value,
    wage_unit,
    title,
    url,
    post_date,
    skills,
    noc,
    matchingScore,
  } = posting;

  const { data: skillsBank, isFetching: isFetchingSkillsBank } = useSkills();
  const content = posting.description ? posting.description : "";
  const isSaved = isPostingSaved(posting._id);

  const date = dayjs(post_date).format("MMM D, YYYY");
  const salary =
    wage_value && wage_value !== "NULL" ? `${wage_value} ${wage_unit}` : "N/A";

  const [expandDesc, setExpandDesc] = useState(false);
  const [showPosessed, setShowPosessed] = useState(false);
  const [showMissing, setShowMissing] = useState(false);
  const handlePosessedToggle = () => setShowPosessed(!showPosessed);
  const handleMissingToggle = () => setShowMissing(!showMissing);

  const isDescTooLong = content.length > 600;
  const description = isDescTooLong
    ? expandDesc
      ? content
      : `${content.substring(0, 600)}...`
    : content;

  let acquiredSkills = [];
  let missingSkills = [];

  for (let skill of skills) {
    if (
      userSkills.filter(
        (userSkill) =>
          userSkill["Skill-Keywords"].toLowerCase() === skill.toLowerCase()
      ).length > 0
    ) {
      acquiredSkills = [...acquiredSkills, skill];
    } else missingSkills = [...missingSkills, skill];
  }

  const { data: courseMatches, isFetching: isFetchingCourseMatches } = useCourseMatches(missingSkills);
  const courses = !isFetchingCourseMatches ? [...courseMatches].slice(0, 5) : [];
  courses.forEach(program => 
    program.skills = program.skills.filter(skill => 
      skillsBank.find(
        (s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase()
      )["KST Level 1"] !== "Skills"
  ))

  // Filter programs with less than 2 provided skills
  let programs = courses.filter(program => program.skills.length > 2)

  const handleSave = () => {
    if (isSaved) unsavePosting(posting._id);
    else savePosting(posting._id);
  };

  // let percentOfSkills = Math.round(
  //   (posting.count / posting.skills.length) * 100
  // );

  let missingSkillsCategories = [];
  let missingSkillsCategoriesSoft = [];
  let acquiredSkillsCategories = [];
  let skillsWithCategories = [];

  for (let skill of skills) {
    if (
      skillsBank.find(
        (s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase()
      )
    ) {
      skillsWithCategories = [
        ...skillsWithCategories,
        skillsBank.find(
          (s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase()
        ),
      ];
    }
  }

  for (let skill of skillsWithCategories) {
    if (
      userSkills.filter(
        (userSkill) =>
          userSkill["Skill-Keywords"].toLowerCase() ===
          skill["Skill-Keywords"].toLowerCase()
      ).length > 0
    ) {
      if (acquiredSkillsCategories.indexOf(skill["KST Level 2"]) !== -1) {
        continue;
      }
      acquiredSkillsCategories = [
        ...acquiredSkillsCategories,
        skill["KST Level 2"],
      ];
      continue;
    }
    if (missingSkillsCategories.indexOf(skill["KST Level 2"]) !== -1) {
      continue;
    }
    if (skill["KST Level 1"].toLowerCase() === "skills") { 
      missingSkillsCategoriesSoft = [
        ...missingSkillsCategoriesSoft,
        skill["KST Level 2"],
      ];
      continue;
    }
    missingSkillsCategories = [
      ...missingSkillsCategories,
      skill["KST Level 2"],
    ];
  }

  let skillColor = matchingScore > 0.5 ? "green.500" : "red.500";

  // return KST lvl 2 skill words for each word in list of lists and return flat list
  function flattenSkills(skillList, isMissing){
    let verList = isMissing ? missingSkills : acquiredSkills;
    return skillList.map((category, index) => (
      skillsWithCategories.filter((s) =>
        s["KST Level 2"].toLowerCase() === category.toLowerCase()).filter((s) =>
          verList.includes(s["Skill-Keywords"])).map((s) => 
          s["Skill-Keywords"]))).flat()
  } 

  // lists to be displayed
  let acquiredSkillsFlattened = flattenSkills(acquiredSkillsCategories, false);
  let missingSkillsFlattened = flattenSkills(missingSkillsCategories, true);
  let missingSkillsSoftFlattened = [...new Set(flattenSkills(missingSkillsCategoriesSoft, true))];

  // boolean state to control skill toggle, threshold value
  const SKILL_COUNT_THRESH = 10;
  const [toggleAcquired, setToggleAcquired] = useState(acquiredSkillsFlattened.length > SKILL_COUNT_THRESH);
  const [toggleMissing, setToggleMissing] = useState(missingSkillsFlattened.length > SKILL_COUNT_THRESH);
  const [toggleMissingSoft, setToggleMissingSoft] = useState(missingSkillsSoftFlattened.length > SKILL_COUNT_THRESH);

  // functions to handle skill toggle button
  const handleToggleAcquired = () => setToggleAcquired(!toggleAcquired);
  const handleToggleMissing = () => setToggleMissing(!toggleMissing);
  const handleToggleMissingSoft = () => setToggleMissingSoft(!toggleMissingSoft);

  // split list in two for fluid grid display
  let split1 = Math.ceil(acquiredSkillsFlattened.length/2);
  let split2 = Math.ceil(missingSkillsFlattened.length/2);
  let split3 = Math.ceil(missingSkillsSoftFlattened.length/2);

  let acquiredSkillsList = toggleAcquired ? [acquiredSkillsFlattened.slice(0,SKILL_COUNT_THRESH/2), acquiredSkillsFlattened.slice(SKILL_COUNT_THRESH/2,SKILL_COUNT_THRESH)] :
                          [acquiredSkillsFlattened.slice(0,split1), acquiredSkillsFlattened.slice(split1,acquiredSkillsFlattened.length)];
  let missingSkillsList = toggleMissing ? [missingSkillsFlattened.slice(0,SKILL_COUNT_THRESH/2), missingSkillsFlattened.slice(SKILL_COUNT_THRESH/2,SKILL_COUNT_THRESH)] :
                          [missingSkillsFlattened.slice(0,split2), missingSkillsFlattened.slice(split2,missingSkillsFlattened.length)];
  let missingSkillsListSoft = toggleMissingSoft ? [missingSkillsSoftFlattened.slice(0,SKILL_COUNT_THRESH/2), missingSkillsSoftFlattened.slice(SKILL_COUNT_THRESH/2, SKILL_COUNT_THRESH)]:
                          [missingSkillsSoftFlattened.slice(0,split3), missingSkillsSoftFlattened.slice(split3,missingSkillsSoftFlattened.length)];
  return (
    <AccordionItem borderColor="gray.400">
      {({ isExpanded }) => (
        <>
          <AccordionButton bg={isExpanded ? "gray.200" : "unset"}>
            <Grid
              flex="1"
              textAlign="left"
              templateColumns={{ base: "75% 25%", sm: "2fr 8rem 3rem" }}
            >
              <Box>
                <Text fontWeight="650">{he.decode(title)}</Text>
                <Text>{city}</Text>
              </Box>
              {/* <Grid alignItems="center">
                <Text fontWeight="500">
                  Occupation: {noc}
                </Text>
              </Grid> */}
              {/* <Grid alignItems="center">
                <Text fontWeight="500" color={skillColor}>
                  {posting.hardSkillCount}/{posting.totalHardSkillCount} Matched Skills
                </Text>
              </Grid> */}
              <Grid
                templateColumns="max-content 1fr"
                alignItems="center"
                gap={1}
              >
                <Center>$</Center>
                <Text>{salary}</Text>
                <TimeIcon />
                <Text>{date}</Text>
              </Grid>
            </Grid>
            <AccordionIcon fontSize="3xl" />
          </AccordionButton> 
          <AccordionPanel pb={12}>
            <Box outline="#1a75ff solid 2px" bg="gray.200">
              <Grid templateColumns={{base: "100%", lg: "repeat(3, 1fr)" }} spacing={6} p={2}>
                <GridItem colSpan={1} mr={2}>
                  <Box align="start">
                    <Text as="span">
                      <Text as="span" fontWeight="500" mr={2}>
                        Employer:
                      </Text>
                      <Text as="span">{employer}</Text>
                    </Text>
                    <Box mt={2}>
                      <Text fontWeight="500">Job Description</Text>
                      <Text fontSize="sm" as="span">
                        <Text as="span" mr={2}>
                          {description}
                        </Text>
                        {/* {isDescTooLong && (
                          <Button
                            variant="wrapper"
                            size="sm"
                            color="blue.500"
                            mb={1}
                            onClick={() =>
                              setExpandDesc((prevValue) => !prevValue)
                            }
                          >
                            {expandDesc ? "Show Less" : "See More"}
                          </Button>
                        )} */}
                      </Text>
                    </Box>

                    <SimpleGrid
                      autoFlow="column"
                      justifyContent="center"
                      gap={4}
                      mt={4}
                    >
                      <Button
                        as={Link}
                        colorScheme="blue"
                        href={url}
                        isExternal
                      >
                        Apply for Job
                      </Button>
                    </SimpleGrid>
                  </Box>
                </GridItem>
                <GridItem colSpan={2}>
                  <Grid
                    p={3}
                    m={3}
                    outline="#c2c2d6 solid 2px"
                    borderRadius="25px"
                    gap={2}
                    templateColumns={{ base: "100%", md: "repeat(2,1fr)"}}
                  >
                    <Box>
                      <HStack justifyContent='space-between' pr={2} pl={2}>
                        <Button size="sm" visibility='hidden'>+</Button>
                        <Heading size="md" textAlign="center" mb={1}>
                          Skills I have
                        </Heading>
                        {acquiredSkillsFlattened.length > SKILL_COUNT_THRESH ?
                          <Button variant="solid" size="sm" color="blue.500" onClick={handleToggleAcquired}>
                            {toggleAcquired ? "+" : "-"}
                          </Button>
                          :
                          <Button size="sm" visibility='hidden'>+</Button>
                      }
                      </HStack>
                      <Grid p={2} gap={2} templateColumns={{ base: "100%", sm: "repeat(2,1fr)"}}>
                      {acquiredSkillsList.map((skillsList, index) =>(
                        skillsList.map(s => 
                        <SkillItem 
                          key={index}
                          label={s}
                        ></SkillItem>
                      )))}
                      </Grid>
                    </Box>
                    <Box>
                    <HStack justifyContent='space-between' pr={2} pl={2}>
                        <Button size="sm" visibility='hidden'>+</Button>
                        <Heading size="md" textAlign="center" mb={1}>
                          Missing Skills
                        </Heading>
                        {missingSkillsFlattened.length > SKILL_COUNT_THRESH ?
                          <Button variant="solid" size="sm" color="blue.500" onClick={handleToggleMissing}>
                          {toggleMissing ? "+" : "-"}
                        </Button>
                        :
                        <Button size="sm" visibility='hidden'>+</Button>
                        }
                      </HStack>
                      <Grid p={2} gap={2} templateColumns={{ base: "100%", sm: "repeat(2,1fr)"}}>
                      {missingSkillsList.map((skillsList, index) =>(
                        skillsList.map(s => 
                        <SkillItem 
                          key={index}
                          isMissing
                          label={s}
                        ></SkillItem>
                      )))}
                      </Grid>
                      <HStack justifyContent='space-between' pr={2} pl={2}>
                        {missingSkillsSoftFlattened.length > 0 ?
                          <Text>Nice to have:</Text>
                          :
                          <Text></Text>
                        }
                        {missingSkillsSoftFlattened.length > SKILL_COUNT_THRESH ?
                          <Button variant="solid" size="sm" color="blue.500" onClick={handleToggleMissingSoft}>
                          {toggleMissingSoft ? "+" : "-"}
                        </Button>
                        :
                        <Button size="sm" visibility='hidden'>+</Button>
                        }
                      </HStack>
                      <Grid p={2} gap={2} templateColumns="repeat(2,1fr)">
                        {missingSkillsListSoft.map((skillsList, index) =>(
                          skillsList.map(s => 
                          <SkillItem 
                            key={index}
                            isMissing
                            label={s}
                          ></SkillItem>
                        )))}
                      </Grid>
                    </Box>
                  </Grid>
                </GridItem>
              </Grid>
              <Box colSpan={2} rowSpan={1}>
                {programs.length > 0 ?
                  <Grid pt={10}>
                  <Heading size="sm" textAlign="center" mb={2}>
                    Tools/Knowledge Development Opportunities
                  </Heading>
                  {!isFetchingCourseMatches && programs ? (
                      <Accordion allowToggle>
                        {programs.map((program) => (
                          <ProgramAccordionItem
                            key={program._id}
                            program={program}
                          />
                        ))}
                      </Accordion>
                  ) : 
                  <Flex justify="center">
                    <Text as="span" mr={2}>Searching </Text> <Spinner size="lg" />
                  </Flex>
                  }
                </Grid>
                :
                ""
              }
                
              </Box>
            </Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default JobPostingItem;
