import React, { useEffect, useState } from "react";
import { Grid, Box, Heading, Text, Spinner, Tooltip } from "@chakra-ui/react";
import ValueVsPrevious from "../../components/DashboardData/ValueVsPrevious";
import useWindowDimensions from "./useWindowDimensions"
import {
  useJobPostingsNumbers,
  useNocToTitle,
  useLocalJobPostings,
  useJobPostingsNumbers7DaysActive,
  useJobPostingsNumbers7DaysExpired,
  useJobPostings,
  selectedOccupations,
  useSelectedOccupations,
  useJobPostingsWithSkillCount,
  useSkillsInJobPostingNumbers,
  useWeeklyEmployers,
  useWeekly,
  useNumEmployers,
  useNumPostings,
  useSkills



} from "../../api";
import axios from "axios";
import BarGraph from "./charts/BarGraph";
import PieGraph from "./charts/PieGraph";
import LineGraph from "./charts/LineGraph";
import palette from "../../util/palette";
import PeriodSelector from "./controls/PeriodSelector"
import { parse } from "ipaddr.js";

const Card = (props) => {
  return <Box px="4" py="5" rounded="lg" shadow="lg" {...props}></Box>;
};

const periodLengthInDays = {
  "7 Days": 7,
  "30 Days": 30,
  "180 Days": 180,
  "1 Year": 365
}

const Data = ({
  period,
  setPeriod,
  city,
  selectedSkills,
  selectedOccupations,
  mapData,
  occupation,
  skill,
  year,
  month,
  date,
}) => {
  const numDays = periodLengthInDays[period];
  const [now] = useState(new Date(Date.now()));
  const [onePeriodAgo, setOnePeriodAgo] = useState(new Date(Date.now() - numDays * 24 * 60 * 60 * 1000));
  const [twoPeriodsAgo, setTwoPeriodsAgo] = useState(new Date(Date.now() - 2 * numDays * 24 * 60 * 60 * 1000));
  const [occupationIsOpen, setOccupationIsOpen] = useState(false);
  const [skillIsOpen, setSkillIsOpen] = useState(false);
  const [occupationLabel, setOccupationLabel] = useState(false);
  const [skillLabel, setSkillLabel] = useState(false);
  const { data: skills } = useSkills();
  const { data: totalJobPostingNumbers } = useJobPostingsNumbers("", "", city, mapData, onePeriodAgo);

  const { data: totalSkillsInJobPostingNumbers } = useSkillsInJobPostingNumbers(city, onePeriodAgo);
  const { data: newActivePosts } = useJobPostingsNumbers7DaysActive(selectedOccupations, selectedSkills, city, mapData);
  const { data: newExpiredPosts } = useJobPostingsNumbers7DaysExpired(selectedOccupations, selectedSkills, city, mapData);
  const { data: postingsWithSkillCount, isFetching: isFetchingPostingCount } = useJobPostingsWithSkillCount(selectedSkills, city, onePeriodAgo);

  useEffect(() => {
    const numDays = periodLengthInDays[period];

    setOnePeriodAgo(new Date(Date.now() - numDays * 24 * 60 * 60 * 1000));
    setTwoPeriodsAgo(new Date(Date.now() - 2 * numDays * 24 * 60 * 60 * 1000));
  }, [period]);

  const occFilter = mapData == 1 ? selectedOccupations.map(occ => occ.NOC) : []
  const skillFilter = mapData == 2 ? selectedSkills : [];

  const { data: numVsEmployers, isFetching: isFetchingNumVsEmployers } = useNumEmployers(onePeriodAgo, now, city, occFilter, skillFilter)
  const { data: numEmployers, isFetching: isFetchingNumEmployers } = useNumEmployers(now, now, city, occFilter, skillFilter)
  const { data: numVsPostings, isFetching: isFetchingNumVsPostings } = useNumPostings("", "", city, mapData, onePeriodAgo, now)
  const { data: numPostings, isFetching: isFetchingNumPostings } = useNumPostings("", "", city, mapData, now, now)
  const {
    data: numVsPostingsOccOrSkill,
    isFetching: isFetchingNumVsPostingsOccOrSkill
  } = useNumPostings(
    selectedOccupations,
    selectedSkills,
    city,
    mapData,
    twoPeriodsAgo,
    onePeriodAgo
  );
  const {
    data: numPostingsOccOrSkill,
    isFetching: isFetchingNumPostingsOccOrSkill
  } = useNumPostings(
    selectedOccupations,
    selectedSkills,
    city,
    mapData,
    onePeriodAgo,
    now
  );

  let matchedSkills = []
  // let newSkill = []
  // let skillstwo = Object.keys(skills["KST Level 1"]).map(e => e.toLowerCase());
  if (skills && totalSkillsInJobPostingNumbers) {
    let newSkill = skills.filter(skillItem => skillItem["KST Level 1"] != "Skills")
    for (let skill of newSkill) {
      for (let numberskill of totalSkillsInJobPostingNumbers) {
        if (numberskill._id.includes(skill["Skill-Keywords"])) matchedSkills.push(numberskill)
      }
    }

    // function compareNumbers(a, b) {
    //   return a - b;
    // }

    // matchedSkills["total"].sort(compareNumbers())
    matchedSkills.sort((a, b) => {
      return b.total - a.total;
    });
  }



  const { data: occupationCounts } = useSelectedOccupations(selectedOccupations, city, onePeriodAgo);
  const { height: screenHeight, width: screenWidth } = useWindowDimensions();


  function parsePeriod(period) {
    return period.split(" ")[1] === "Days" ? period.split(" ")[0] : 365;
  }

  const postHistory = function (postings, period) {
    let start = period
    let hist = []
    let total = postings.s;
    for (let i = start; i > 0; i--) {
      let dt = new Date(Date.now() - i * 24 * 60 * 60 * 1000)
      let dtf = `${dt.getFullYear()}-${dt.getMonth() < 9 ? "0" + (dt.getMonth() + 1).toString() : dt.getMonth() + 1}-${dt.getDate() < 10 ? "0" + (dt.getDate()).toString() : dt.getDate()}`
      total += postings.new.length > 0 ?
        postings.new.filter(post => post.id === dtf).length > 0 ?
          postings.new.filter(post => post.id === dtf)[0].n : 0 : 0
      total -= postings.old.length > 0 ?
        postings.old.filter(post => post.id === dtf).length > 0 ?
          postings.old.filter(post => post.id === dtf)[0].n : 0 : 0
      hist.push({ date: dtf, n: total })
    }
    return hist
  };

  const { data: tseries, isFetching: isFetchingTseries } = useWeekly(city, parsePeriod(period), occFilter, skillFilter)
  const { data: rtseries, isFetching: isFetchingRTseries } = useWeekly("", parsePeriod(period), occFilter, skillFilter)
  const history = !isFetchingTseries ? postHistory(tseries, parsePeriod(period)) : false
  const regionalHist = !isFetchingRTseries ? postHistory(rtseries, parsePeriod(period)) : false

  const getTotal = (input) => {
    let total = 0;
    for (let i = 0; i < input.length; i++) {
      total += input[i].total;
    }
    return total;
  };
  const colors = palette("mpn65", 5).map((hex) => "#" + hex);

  let labels = [];
  let occCounts = [];

  let max = occupationCounts ? occupationCounts > 5 ? 5 : occupationCounts.length : 0;

  for (let i = 0; i < max; i++) {
    let temp = selectedOccupations.filter((noc) => noc.NOC === occupationCounts[i]._id);
    labels[i] = temp[0].Title;
  }

  if (occupationCounts) {
    occCounts = occupationCounts.map((occup) => occup = occup.total);
  }
  for (let i = 0; i < selectedOccupations.length; i++) {
    if (!labels.includes(selectedOccupations[i].Title)) {
      if (labels.length < 5) {
        labels.push(selectedOccupations[i].Title);
        occCounts.push(0);
      }
    }
  }

    const handlePeriodChange = (period) => {
      setPeriod(period);
      console.log(period);
    };
    
    // const handleOccupationTooltip = (status, tooltipLabel) =>{
    //   setOccupationIsOpen(status);
    //   setOccupationLabel(tooltipLabel);
    // }
    
    //  const handleSkillTooltip = (status, tooltipLabel) => {
    //    setSkillIsOpen(status);
    //    setSkillLabel(tooltipLabel);
    //  };
    
  
  

  return (
    <Grid bg="white" p={4} borderTopRadius="xl" zIndex={2}>
      <Grid flex="1" gap="1rem" templateColumns={{base: "max-content", md: "max-content max-content", xl: "1fr max-content max-content" }}>
        <Heading size="lg" fontWeight="bold" gridColumn={{ base: "1 / -1", xl: "1 / 2" }}>
          {city ? `${city} Analytics` : "Eastern Ontario Analytics"}
        </Heading>
        <Box>
          <Text fontSize="xl">
            Last Updated: Today - {month}/{date}/{year}
          </Text>
        </Box>
        <PeriodSelector selected={period} onPeriodChange={handlePeriodChange} />
      </Grid>

      <Grid
        templateColumns={
          screenWidth > 1000 ? "repeat(10, 1fr)" : "repeat(1,1fr)"
        }
        m={4}
        gap={4}
      >
        <>
          <Card
            gridColumn={screenWidth > 1000 ? "span 4" : "span 1"}
            gridRow="span 2"
            position=" relative"
            width="100%"
          >
            {/* <Tooltip
              isOpen={occupationIsOpen}
              label={occupationLabel}
              placement="bottom"
            > */}
            <Heading size="md">Top 5 Occupations in demand</Heading>
            {/* </Tooltip> */}
            <Box position="relative" width="100%" height="20rem" pb={2}>
              {totalJobPostingNumbers ? (
                <PieGraph
                  // handleToolTip={handleOccupationTooltip}
                  data={{
                    labels: totalJobPostingNumbers
                      ? totalJobPostingNumbers[1]
                        ? totalJobPostingNumbers[2]
                          ? totalJobPostingNumbers[3]
                            ? totalJobPostingNumbers[4]
                              ? [
                                  totalJobPostingNumbers[0].nocTitle[0].Title,
                                  totalJobPostingNumbers[1].nocTitle[0].Title,
                                  totalJobPostingNumbers[2].nocTitle[0].Title,
                                  totalJobPostingNumbers[3].nocTitle[0].Title,
                                  totalJobPostingNumbers[4].nocTitle[0].Title,
                                ]
                              : [
                                  totalJobPostingNumbers[0].nocTitle[0].Title,
                                  totalJobPostingNumbers[1].nocTitle[0].Title,
                                  totalJobPostingNumbers[2].nocTitle[0].Title,
                                  totalJobPostingNumbers[3].nocTitle[0].Title,
                                ]
                            : [
                                totalJobPostingNumbers[0].nocTitle[0].Title,
                                totalJobPostingNumbers[1].nocTitle[0].Title,
                                totalJobPostingNumbers[2].nocTitle[0].Title,
                              ]
                          : [
                              totalJobPostingNumbers[0].nocTitle[0].Title,
                              totalJobPostingNumbers[1].nocTitle[0].Title,
                            ]
                        : [totalJobPostingNumbers[0].nocTitle[0].Title]
                      : [],
                    data: totalJobPostingNumbers
                      ? totalJobPostingNumbers[1]
                        ? totalJobPostingNumbers[2]
                          ? totalJobPostingNumbers[3]
                            ? totalJobPostingNumbers[4]
                              ? [
                                  totalJobPostingNumbers[0].total,
                                  totalJobPostingNumbers[1].total,
                                  totalJobPostingNumbers[2].total,
                                  totalJobPostingNumbers[3].total,
                                  totalJobPostingNumbers[4].total,
                                ]
                              : [
                                  totalJobPostingNumbers[0].total,
                                  totalJobPostingNumbers[1].total,
                                  totalJobPostingNumbers[2].total,
                                  totalJobPostingNumbers[3].total,
                                ]
                            : [
                                totalJobPostingNumbers[0].total,
                                totalJobPostingNumbers[1].total,
                                totalJobPostingNumbers[2].total,
                              ]
                          : [
                              totalJobPostingNumbers[0].total,
                              totalJobPostingNumbers[1].total,
                            ]
                        : [totalJobPostingNumbers[0].total]
                      : [],
                  }}
                  colors={colors}
                />
              ) : (
                <Box position="absolute" top="50%" left="45%">
                  <Spinner size="xl" />
                </Box>
              )}
            </Box>
          </Card>
          <Card
            gridColumn={screenWidth > 1000 ? "span 3" : "span 1"}
            gridRow="span 1"
            position=" relative"
            width="100%"
          >
            <ValueVsPrevious
              title={
                mapData == 1 && selectedOccupations.length > 0
                  ? "Total Job Postings for Selected Occupation(s)"
                  : mapData == 2 && selectedSkills.length > 0
                  ? "Total Job Postings for Selected Skill(s)"
                  : "Total Job Postings"
              }
              // value={!isFetchingNumPostings ? numPostings : "Loading Data..."}
              value={
                history ? history[history.length - 1].n : "Loading Data..."
              }
              unit=""
              // vsValue={!isFetchingNumVsPostings ? numVsPostings : "0"}
              vsValue={history ? history[0].n : "Loading Data..."}
              vsText={` versus ${period.toLowerCase()} prior`}
            ></ValueVsPrevious>
          </Card>
          <Card
            gridColumn={screenWidth > 1000 ? "span 3" : "span 1"}
            gridRow="span 1"
            position=" relative"
          >
            <ValueVsPrevious
              title="Employers"
              value={!isFetchingNumEmployers ? numEmployers : "Loading Data..."}
              unit=""
              vsValue={!isFetchingNumVsEmployers ? numVsEmployers : "0"}
              vsText={` versus ${period.toLowerCase()} prior`}
            ></ValueVsPrevious>
          </Card>
          <Card
            gridColumn={screenWidth > 1000 ? "span 6" : "span 1"}
            gridRow="span 3"
            position=" relative"
            width="100%"
          >
            <Heading size="md">
              {mapData == 1 && selectedOccupations.length > 0
                ? "Job Posting History for Selected Occupation(s)"
                : mapData == 2 && selectedSkills.length > 0
                ? "Job Posting History for Selected Skill(s)"
                : "Job Posting History"}
            </Heading>
            <Box position="relative" top="1rem" height="30rem" width="100%">
              {history ? (
                <LineGraph
                  data={
                    !isFetchingTseries && !isFetchingRTseries
                      ? city !== ""
                        ? [
                            history.map((ts) => ts.n),
                            regionalHist.map((ts) => ts.n),
                          ]
                        : history && history.map((ts) => ts.n)
                      : []
                  }
                  label={"# Job Postings"}
                  labels={history && history.map((ts) => ts.date)}
                  period={parsePeriod(period)}
                  city={city}
                />
              ) : (
                <Box position="absolute" top="50%" left="49%">
                  <Spinner size="xl" />
                </Box>
              )}
            </Box>
          </Card>
        </>
        <>
          <Card
            gridColumn={screenWidth > 1000 ? "span 4" : "span 1"}
            gridRow="span 2"
            position=" relative"
            width="100%"
          >
            {/* <Tooltip isOpen={skillIsOpen} label={skillLabel} placement="bottom"> */}
            <Heading size="md">Top 5 Skills in demand</Heading>
            {/* </Tooltip> */}
            <Box
              position="relative"
              top="1rem"
              width="100%"
              height="20rem"
              pb={2}
            >
              {totalSkillsInJobPostingNumbers ? (
                <PieGraph
                  // handleToolTip={handleSkillTooltip}
                  data={{
                    labels: matchedSkills
                    ? matchedSkills[0]
                      ? matchedSkills[1]
                        ? matchedSkills[2]
                          ? matchedSkills[3]
                            ? matchedSkills[4]
                              ? [
                                  matchedSkills[0]._id,
                                  matchedSkills[1]._id,
                                  matchedSkills[2]._id,
                                  matchedSkills[3]._id,
                                  matchedSkills[4]._id,
                                ]
                              : [
                                  matchedSkills[0]._id,
                                  matchedSkills[1]._id,
                                  matchedSkills[2]._id,
                                  matchedSkills[3]._id,
                                ]
                            : [
                                matchedSkills[0]._id,
                                matchedSkills[1]._id,
                                matchedSkills[2]._id,
                              ]
                          : [matchedSkills[0]._id, matchedSkills[1]._id]
                        : [matchedSkills[0]._id]
                      : [] : [],
                    data: matchedSkills
                    ? matchedSkills[0]
                      ? matchedSkills[1]
                        ? matchedSkills[2]
                          ? matchedSkills[3]
                            ? matchedSkills[4]
                              ? [
                                  matchedSkills[0].total,
                                  matchedSkills[1].total,
                                  matchedSkills[2].total,
                                  matchedSkills[3].total,
                                  matchedSkills[4].total,
                                ]
                              : [
                                  matchedSkills[0].total,
                                  matchedSkills[1].total,
                                  matchedSkills[2].total,
                                  matchedSkills[3].total,
                                ]
                            : [
                                matchedSkills[0].total,
                                matchedSkills[1].total,
                                matchedSkills[2].total,
                              ]
                          : [matchedSkills[0].total, matchedSkills[1].total]
                        : [matchedSkills[0].total]
                      : [] : [],
                  }}
                  colors={colors}
                />
              ) : (
                <Box position="absolute" top="50%" left="45%">
                  <Spinner size="xl" />
                </Box>
              )}
            </Box>
          </Card>
        </>
      </Grid>
    </Grid>
  );
};

export default Data;
