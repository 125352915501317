const colors = {
   black: "#16161D",
   error: "red.500",
   warning: "#DD6B20",
};

export default colors;

/*
Can add colours with multiple values

brand: {
	50: '#FFF4DA',
	100: '#FFE3AD',
	200: '#FFD07D',
	300: '#FFBD4B',
	400: '#FFAA1A',
	500: '#E69000',
	600: '#B37000',
	700: '#815000',
	800: '#4E2F00',
	900: '#1F0F00',
},
*/
