import React, { useState } from "react";
import { Box, Button, Heading, Grid, Flex, Input, InputGroup, InputLeftElement, Select, Spinner, Text, VStack, Divider } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

//Components
import { useSkills, useSkillInJobPostings, useSkillInCities } from "../../api";

import CityBarGraph from "./CityBarGraph";

const SkillsExplorer = () => {
   const [skill, setSkill] = useState("Accounting");
   const { data: numOfJobs, isFetching: isFetchingNumOfJobs} = useSkillInJobPostings(skill);
   const { data: citiesOfSkill, isFetching: isFetchingCitiesOfSkill } = useSkillInCities(skill);
   const { data: skills, isFetching: isFetchingSkills } = useSkills();
   const [numOfCities, setNumOfCities] = useState(10);
   const [filteredSkills, setFilteredSkills] = useState([]);
   const [sortFlag, setSortFlag] = useState(0);

   if (!isFetchingSkills && sortFlag === 0){
     setFilteredSkills(skills)
     setSortFlag(1);
   }

   const setFilterText = (text) => {
     if (text === ""){
       setFilteredSkills(skills)
       return
     }
    setFilteredSkills([...skills].filter((skill) => skill['Skill-Keywords'].toLowerCase().includes(text.toLowerCase())))
    return
   }

   const setFilterDrop = (filter) => {
     if (filter === 'No Filter'){
       setFilteredSkills(skills)
       return
     }
     setFilteredSkills(skills.filter(skill => skill['KST Level 1'] === filter))
     return
   }

   let data = citiesOfSkill
      ? { labels: [...citiesOfSkill.map((city) => city._id)].slice(0, numOfCities), totals: [...citiesOfSkill.map((city) => city.total)].slice(0, numOfCities) }
      : {
           labels: [],
           totals: [],
        };

   return (isFetchingSkills && !skills) || (isFetchingNumOfJobs || isFetchingCitiesOfSkill) ? (
      <Grid alignItems='center' justifyItems='center' height='calc(100vh - 5rem)'>
      <Flex justify="center">
        <Text as="span" mr={2}>Loading ... Please Wait</Text><Spinner />
      </Flex>
      </Grid>
   ) : (
      <Box bg='gray.300' height={{ base: "100%", lg: 'calc(180vh - 5rem)' }} p={5}>
         <Grid templateColumns={{ base: "1fr" , lg: '1fr max-content'}} height='100%' gap={5}>
            <Grid templateRows='max-content max-content max-content 1fr' height={{ base: "28rem", lg: '100%' }} gap={4} p={4} bg='white' overflow='auto' borderRadius='md'>
               <Heading size='lg'pt='1rem'>Skills</Heading>
               <Text fontSize='md'>Type your skill below to find out where it is in demand.</Text>
               <InputGroup>
                  <InputLeftElement height='100%' pointerEvents='none' children={<SearchIcon />} />
                  <Input aria-label="skill form" placeholder='Type your skill' onChange={(e) => setFilterText(e.target.value)} />
               </InputGroup>
               <VStack overflow='auto'>
                  {filteredSkills.map((skill) => (
                     <>
                        <Grid templateColumns='1fr max-content' key={skill._id} width='100%' alignItems='center' p={1} pr={3} gap={3}>
                           <Text fontSize='18px'>{skill['Skill-Keywords']}</Text>
                           <Button size='sm' onClick={() => setSkill(skill['Skill-Keywords'])} colorScheme='gray'>
                              Explore
                           </Button>
                        </Grid>
                        <Divider />
                     </>
                  ))}
               </VStack>
            </Grid>
            <Grid bg='white' templateRows='repeat(3, max-content)' overflow='auto' templateColumns='1fr' alignContent='center' justifyItems='center' px={6} gap={4} borderRadius='md'>
               <Grid textAlign='center'pt='0rem'>
                  <Text fontSize={{ base: "24px", lg: '30px' }} marginTop="1rem">
                     <strong>{skill}</strong>
                  </Text>
                  <Text fontSize={{ base: "16px", lg: '24px' }}>
                     <strong>{numOfJobs}</strong> active job postings in <strong>{citiesOfSkill ? citiesOfSkill?.length : 0}</strong> cities and communities
                  </Text>
               </Grid>
               <Grid fontSize={{ base: "16px", lg: '24px' }} templateColumns={{ base: "max-content max-content", lg: 'max-content max-content max-content' }} alignItems='center' justifyContent='center'>
                  <Text as='span'>Showing top</Text>
                  <Select width='19' size='md' display='inline-block' mx={2} onChange={(e) => setNumOfCities(+e.target.value)}>
                     <option value='10'>10</option>
                     <option value='20'>20</option>
                     <option value='50'>50</option>
                  </Select>{" "}
                  <Text as='span' gridColumn={{ base: "1 / -1", lg: "3 / 4"}}>cities and communities</Text>
               </Grid>
               <Grid>
                  <Text fontSize='16px'>* Please visit Job Seekers tool for job postings.</Text>
               </Grid>
               <Grid justifyItems="left" width='80%' pb='0'>
                  <Text fontSize={{ base: "20px", lg: '24px' }} fontWeight="bold" >Cities and Communities</Text>
               </Grid>
               <Box height={{ base: "40rem", lg: 'calc(120vh - 5rem)' }} width={{ base: "80vw", lg: "55vw", xl: "60vw", xxl: "70vw" }}>
                  <CityBarGraph data={data} />
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};

export default SkillsExplorer;
