import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Grid, Image, Stack } from "@chakra-ui/react";

import logo from "../images/logo.png";

const NavBarLink = ({ label, path }) => {
   const { pathname } = useLocation();
   const isActive = pathname === path;

   return (
      <Button
         fontWeight='400'
         variant='ghost'
         borderRadius='sm'
         as={Link}
         to={path}
         _hover={{ textDecoration: "none" }}
         color='white'
         bg={isActive ? "#28807C" : "unset"}
         height='2rem'
         fontSize={{ base: "16px", md: "1.2rem" }}
         padding={{ base: "0.2rem 2rem", sm: "0 0.4rem", md: "0 1rem"}}
      >
         {label}
      </Button>
   );
};

const NavBar = () => {
   return (
      <Grid as='nav' px={4} bg='purple.700' spacing={4} templateColumns={{ base: '1fr', sm: "1fr max-content"}} alignContent='center' height={{ base: "unset", sm: '4rem' }} paddingBottom={{ base: "1rem", sm: 0 }}>
        {/* <Link to='/'> */}
            <Image src={logo} width='6.5rem' alt='Workforce Logo' margin={{ base: "1rem", sm: 0 }} mr={4} />
        {/* </Link> */}
         <Stack spacing={2} direction={{ base: "column", sm: "row" }} alignItems="center">
            <NavBarLink path='/skills-assessment' label='Job Seekers' />
            <NavBarLink path='/skills-explorer' label='Skills Explorer' />
            <NavBarLink path='/occupation-explorer' label='Employer Tools' />
            <NavBarLink path='/dashboard' label='Analytics Dashboard' />
         </Stack>
      </Grid>
   );
};

export default NavBar;
