import axios from "axios";
import { useQuery } from "react-query";

export const useSkills = () => {
   return useQuery("skills", async () => {
      const { data } = await axios.post("/skills/");
      return data;
   });
};

export const useSkillInCities = (skill) => {
  return useQuery(["skillInCities", skill], async () => {
      const { data } = await axios.post("/skills/cities", { skill });
      return data;
  });
}

export const useJobPostingsWithSkillCount = (skills, city, ldate) => {
  return useQuery(["postingsWithSkills", skills, city, ldate], async () => {
    const { data } = await axios.post("/skills/job-count", { skills, city, ldate });
    return data
  })
}

export const useChangeFilter = (filter) => {
  return useQuery(["ChangeFilter", filter], async () => {
      const { data } = await axios.post("/skills/filter", { filter });
      return data;
  });
}

export const useCourses = () => {
   return useQuery("courses", async () => {
      const { data } = await axios.post("/courses/");
      return data;
   });
};

export const useCourseMatches = (missingCourses) => {
  return useQuery(["courseMatches", missingCourses], async () => {
      const { data } = await axios.post("/courses/matches", { missingCourses });
      return data;
  });
};

export const useJobTitles = () => {
  return useQuery("jobTitles", async () => {
     const { data } = await axios.post("/job-titles/");
     return data;
  });
};

export const useJobSeekersInCity = (city) => {
  return useQuery(["JobSeekersInCity", city], async () => {
      const { data } = await axios.post("/job-seekers/city", { city }
      );
      return data;
  });
}

export const useJobPostings = () => {
  return useQuery("jobPostings", async () => {
     const { data } = await axios.post("/job-postings/");
     return data;
  });
};

export const useSortedJobPostings = (userSkills) => {
  return useQuery(["SortJobPostings", userSkills], async () => {
    const { data } = await axios.post("/job-postings/sorted", { userSkills });
    return data;
  });
}

export const useSelectedOccupations = (nocCodes,city, ldate) => {
  return useQuery(["SortJobPostings", nocCodes,city, ldate], async () => {
    const { data } = await axios.post("/job-postings/occupations", { nocCodes,city, ldate });
    return data;
  });
}


export const useJobPostingsNumbers = (nocCodes, skills, city, mapData, ldate) => {
  return useQuery(["JobPostingsNumbers", nocCodes, skills, city, mapData, ldate], async () => {
      const { data } = await axios.post("/job-postings/numbers", { nocCodes, skills, city, mapData, ldate});
      return data;
  });
}

export const useSkillsInJobPostingNumbers = (city, ldate) => {
  return useQuery(["skillsInJobPostingsNumbers", city, ldate], async () => {
      const { data } = await axios.post("/job-postings/skillNumbers", { city, ldate });
      return data;
  });
}


export const useJobPostingsNumbers7DaysActive = (nocCodes, skills, city, mapData) => {
  return useQuery(["JobPostingsNumbers7DaysActive", nocCodes, skills, city, mapData], async () => {
      const { data } = await axios.post("/job-postings/numbers7DaysActive", { nocCodes, skills, city, mapData });
      return data;
  });
}

export const useJobPostingsNumbers7DaysExpired = (nocCodes, skills, city, mapData) => {
  return useQuery(["JobPostingsNumbers7DaysExpired", nocCodes, skills, city, mapData], async () => {
      const { data } = await axios.post("/job-postings/numbers7DaysExpired", { nocCodes, skills, city, mapData });
      return data;
  });
}


export const useJobPostingsInCities = (nocCodes, skills) => {
  return useQuery(["JobPostingsInCities", nocCodes, skills], async () => {
      const { data } = await axios.post("/job-postings/cities", { nocCodes, skills });
      return data;
  });
}

export const useActiveJobPostings = () => {
  return useQuery("activeJobPostings", async () => {
      const { data } = await axios.post("/job-postings/active");
      return data;
  });
}

export const useUpdatedDate = () => {
  return useQuery("updatedDate", async () => {
    const {data} = await axios.post("/job-postings/lastUpdate");
    return data;
  });
}
export const useLocalJobPostings = (city) => {
  return useQuery(["localJobPostings", city], async () => {
      const { data } = await axios.post("/job-postings/local", { city });
      return data;
  });
}

export const useSkillInJobPostings = (skill) => {
  return useQuery(["skillInJobPostings", skill], async () => {
      const { data } = await axios.post("/job-postings/skills", { skill });
      return data;
  });
}

export const useSearchNocs = (text) => {
  return useQuery(["nocs", text], async () => {
     const { data } = await axios.post("/nocs/search", { text });
     return data;
  });
};

export const useNocById = (nocId) => {
  return useQuery(["noc", nocId], async () => {
     const { data } = await axios.post(`/nocs/${nocId}`);
     return data;
  });
};

export const useSortedOccupations = (userSkills) => {
  return useQuery(["SortOccupations", userSkills], async () => {
      const { data } = await axios.post("/nocs/sorted", { userSkills }
      );
      return data;
  });
}

export const useOccupations = (userSkills) => {
  return useQuery(["occupations", userSkills], async () => {
      const { data } = await axios.post("/nocs/sorted-nocs", { userSkills });
      return data;
  });
}

export const useOccupationsByText = (userSkills, text) => {
  return useQuery(["occupations", userSkills, text], async () => {
      const { data } = await axios.post("/nocs/sorted-nocs-by-text", { userSkills, text });
      return data;
  });
}


export const useCollegeEducation = () => {
  return useQuery("collegeEducation", async () => {
     const { data } = await axios.post("/education/college");
     return data;
  });
};

export const useUniEducation = () => {
  return useQuery("uniEducation", async () => {
     const { data } = await axios.post("/education/university");
     return data;
  });
};

export const useNumEmployers = (ldate, hdate, city, occ, skills) => {
  return useQuery(["numEmployers", ldate, hdate, city, occ, skills], async () => {
      const { data } = await axios.post("/job-postings/num-employers", { ldate, hdate, city, occ, skills });
      return data;
  });
};

export const useNumPostings = (nocCodes, skills, city, mapData, ldate, hdate) => {
  return useQuery(["numPostings", nocCodes, skills, city, mapData, ldate, hdate], async () => {
      const { data } = await axios.post("/job-postings/num-postings", { nocCodes, skills, city, mapData, ldate, hdate });
      return data;
  });
};

export const useWeekly = (city, period, occ, skills) => {
  return useQuery(["weekly", city, period, occ, skills], async () => {
      const { data } = await axios.post("/job-postings/weekly", { city, period, occ, skills });
      return data;
  });
};
