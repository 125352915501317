import { useToken } from "@chakra-ui/react";
import React from "react";
import { Line } from "react-chartjs-2";
import { rgbColor } from "../../../util/palette";

const LineGraph = (props) => {
  const [blue300] = useToken("colors", ["blue.300"]);
  const period = props.period > 30 ? 'month' : props.period > 7 ? 'week' : 'day'
  const ps = props.period > 180 ? 1 : props.period > 30 ? 2 : props.period > 7 ? 3 : 4
  const data = props.data.length == 2 ?
  [ {
      type:'line',
      fill: false,
      label: props.city + " Postings",
      backgroundColor: "rgb(52, 128, 235)",
      borderColor:  "rgb(52, 128, 235)",
      data: props.data[0],
      pointRadius: ps,
    }, {
      type:'line',
      fill: false,
      label: 'Eastern Ontario Postings',
      backgroundColor: "rgb(192, 52, 235)" ,
      borderColor: "rgb(192, 52, 235)",
      data: props.data[1],
      pointRadius: ps,
    }
  ] :
  [
    {
      type:'line',
      fill: false,
      label: 'Active Postings',
      backgroundColor: "rgb(192, 52, 235)" ,
      borderColor: "rgb(192, 52, 235)",
      data: props.data,
      pointRadius: ps,
    },
  ]

  return (
    <Line
      type="line"
      data={{
        labels: props.labels,
        datasets: data,
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          // onClick: (e) => e.stopPropagation(),
          labels: {
            fontSize: 14,
            fontColor: "rgba(0,0,0,1)",
          },
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              displayFormats: {'day': 'MMM DD'},
              unit: period,
              // min: props.labels[0]
            },
            ticks: {
              // source: 'data'
              // autoSkip: true,
              // maxRotation:0,
              // minRotation:0,
            }
          }]
        }
      }}
    ></Line>
  );
};

export default LineGraph;
