import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// components
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
//pages
import Home from "./pages/Home/Home";
import SkillsAssessment from "./pages/SkillsAssessment/SkillsAssessment";
import SkillsExplorer from "./pages/SkillsExplorer/SkillsExplorer";
import OccupationExplorer from "./pages/OccupationExplorer/OccupationExplorer";
import NewDashboard from "./pages/Dashboard/NewDashboard";
import ChartTesting from "./pages/Dashboard/ChartTesting";

function Routes() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/occupation-explorer" component={OccupationExplorer} />
        <Route path="/skills-assessment" component={SkillsAssessment} />
        <Route path="/skills-explorer" component={SkillsExplorer} />
        <Route path="/dashboard" component={NewDashboard} />
        <Route path="/charts" component={ChartTesting} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default Routes;
