const Button = {
   variants: {
      wrapper: {
         bg: "none",
         _hover: { bg: "none" },
         h: "max-content",
         w: "max-content",
         p: 0,
         m: 0,
      },
   },
};

export default Button;
