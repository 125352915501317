import theme from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";

// context provider
import { UserProvider } from "./contexts";

import Routes from "./Routes";

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         staleTime: Infinity,
      },
   },
});

function App() {
   return (
      <ChakraProvider resetCSS theme={theme}>
         <QueryClientProvider client={queryClient}>
            <UserProvider>
               <div className='app'>
                  <Routes />
               </div>
            </UserProvider>
         </QueryClientProvider>
      </ChakraProvider>
   );
}

export default App;
