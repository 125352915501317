import React from "react";
import { Box } from "@chakra-ui/react";

export const Wrapper = ({ children, variant = "regular", ...props }) => {
   return (
      <Box mx='auto' maxW={variant === "regular" ? "1600px" : "600px"} w='100%' {...props}>
         {children}
      </Box>
   );
};
