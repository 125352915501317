import React, { useState } from "react";
import { GoCalendar, GoLocation } from "react-icons/go";
import { TimeIcon } from "@chakra-ui/icons";
import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Button, Grid, Text, HStack, Tag } from "@chakra-ui/react";

const ProgramAccordionItem = ({ program }) => {
   const [expandDesc, setExpandDesc] = useState(false);

   const isDescTooLong = program["Program Description (Highlights)"].length > 400;
   const description = isDescTooLong ? (expandDesc ? program["Program Description (Highlights)"] : `${program["Program Description (Highlights)"].substring(0, 600)}...`) : program["Program Description (Highlights)"];
   const timeScale = program["Program Length (weeks or years)"].split(" ")[0] > 4 ? "weeks" : "years";

   return (
     <AccordionItem borderColor="gray.400">
       {({ isExpanded }) => (
         <>
           <AccordionButton bg={isExpanded ? "gray.200" : "unset"}>
             <Grid
               flex="1"
               textAlign="left"
               templateColumns="1fr max-content 3rem"
             >
               <Box>
                 <Text fontWeight="500">{program["Program Name"]} - {program["Institution Name"]}</Text>
                 <HStack flexWrap="wrap">
                   <Text>Missing Skills:</Text>
                    {program["skills"].map((item, index) => ( // temp cap
                    <Tag
                    margin={1}
                    colorScheme="red"
                    key={index}
                    fontSize="sm"
                    textTransform="capitalize"
                    isTruncated
                  >{item}
                    </Tag>
                    ))}

                 </HStack>
               </Box>
               <Grid templateColumns="repeat(2, max-content)" columnGap={6}>
                 <Grid
                   templateColumns="max-content 1fr"
                   alignItems="center"
                   gap={1}
                 >
                   <GoLocation />
                   <Text>{program["Location/ City"]}</Text>
                 </Grid>
                 <Grid
                   templateColumns="max-content 1fr"
                   alignItems="center"
                   gap={1}
                   gridColumn="2 / 3"
                 >
                   <TimeIcon />
                   <Text>{program["Program Length (weeks or years)"].split(" ")[0]} {timeScale}</Text>
                 </Grid>
               </Grid>
             </Grid>
             <AccordionIcon fontSize="3xl" />
           </AccordionButton>
           <AccordionPanel pb={8}>
             <Grid gap={2}>
               <Text fontWeight="500">
                 Credential: {program["Credential"]}
               </Text>
               <Box>
                 <Text fontWeight="500">Program Description</Text>
                 <Text fontSize="sm" as="span">
                   <Text as="span" mr={2}>
                     {description}
                   </Text>
                   {isDescTooLong && (
                     <Button
                       variant="wrapper"
                       size="sm"
                       color="blue.500"
                       mb={1}
                       onClick={() => setExpandDesc((prevValue) => !prevValue)}
                     >
                       {expandDesc ? "Show Less" : "See More"}
                     </Button>
                   )}
                 </Text>
               </Box>
               <Button
                 as="a"
                 target="_blank"
                 rel="noreferrer noopener"
                 mt={2}
                 href={program["Program URL"]}
                 justifySelf="center"
                 colorScheme="blue"
               >
                 View program at {program["Institution Name"]}
               </Button>
             </Grid>
           </AccordionPanel>
         </>
       )}
     </AccordionItem>
   );
};

export default ProgramAccordionItem;
