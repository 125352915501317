import React, { useState } from "react";
import {
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionIcon,
   AccordionPanel,
   Box,
   Button,
   Center,
   Grid,
   Heading,
   Text,
   GridItem,
   HStack,
   Spinner,
   Flex,
   Tag,
   UnorderedList,
   ListItem,
} from "@chakra-ui/react";
import { useUserContext } from "./../../contexts/UserContext";
import { useSkills, useCourseMatches } from "../../api";
import ProgramAccordionItem from "./../CareerExplorer/ProgramAccordionItem";

const SkillItem = ({ isMissing, label }) => {
   return (
      <Center p={2} bg={`${isMissing ? "red" : "green"}.100`} color={`${isMissing ? "red" : "green"}.900`} fontSize='sm' fontWeight='600' borderRadius='md' textAlign='center'>
         {label}
      </Center>
   );
};

const OccupationItem = ({ posting }) => {
   const { userSkills } = useUserContext();
   const noc = posting.NOC;
   const title = posting.Title;
   const content = posting.Description;
   const skillKeywords = posting["Skill-Keywords"];

   const jobTitles = posting.AllExampels?.[0] ? posting.AllExampels[0] : [];
   const duties = posting.MainDuties?.[0] ? posting.MainDuties[0] : [];

   console.log({ jobTitles, duties });

   const { data: skillsBank } = useSkills();

   const [expandDesc, setExpandDesc] = useState(false);

   const isDescTooLong = content.length > 600;
   const description = isDescTooLong ? (expandDesc ? content : `${content.substring(0, 600)}...`) : content;

   let acquiredSkills = [];
   let missingSkills = [];

   for (let skill of skillKeywords) {
      if (userSkills.filter((userSkill) => userSkill["Skill-Keywords"].toLowerCase() === skill.toLowerCase()).length > 0) {
         acquiredSkills = [...acquiredSkills, skill];
      } else missingSkills = [...missingSkills, skill];
   }

   const { data: courseMatches, isFetching: isFetchingCourseMatches } = useCourseMatches(missingSkills);
   const courses = !isFetchingCourseMatches ? [...courseMatches].filter((course) => course.count > 0).slice(0, 5) : [];
   courses.forEach(
      (program) =>
         (program.skills = program.skills.filter((skill) => skillsBank.find((s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase())["KST Level 1"] !== "Skills"))
   );

   // Filter programs with less than 2 provided skills
   let programs = courses.filter((program) => program.skills.length > 2);

   let missingSkillsWithCategories = [];
   let acquiredSkillsWithCategories = [];
   //these contain ever single skill with their categories

   let missingSkillsCategories = [];
   let acquiredSkillsCategories = [];
   //these just contain skill category names without duplicates

   for (let skill of acquiredSkills) {
      if (skillsBank.find((s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase())) {
         acquiredSkillsWithCategories = [...acquiredSkillsWithCategories, skillsBank.find((s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase())];
      }
   }

   for (let skill of missingSkills) {
      if (skillsBank.find((s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase())) {
         missingSkillsWithCategories = [...missingSkillsWithCategories, skillsBank.find((s) => s["Skill-Keywords"].toLowerCase() === skill.toLowerCase())];
      }
   }

   for (let skill of acquiredSkillsWithCategories) {
      if (skill["KST Level 1"].toLowerCase() === "skills") {
         continue;
      }
      if (acquiredSkillsCategories.indexOf(skill["KST Level 2"]) !== -1) {
         continue;
      }

      acquiredSkillsCategories = [...acquiredSkillsCategories, skill["KST Level 2"]];
   }
   for (let skill of missingSkillsWithCategories) {
      if (skill["KST Level 1"].toLowerCase() === "skills") {
         continue;
      }
      if (missingSkillsCategories.indexOf(skill["KST Level 2"]) !== -1) {
         continue;
      }
      missingSkillsCategories = [...missingSkillsCategories, skill["KST Level 2"]];
   }

   // return KST lvl 2 skill words for each word in list of lists and return flat list
   function flattenSkills(skillList, isMissing) {
      let skillCat = isMissing ? missingSkillsWithCategories : acquiredSkillsWithCategories;
      let verList = isMissing ? missingSkills : acquiredSkills;
      return skillList
         .map((category, index) =>
            skillCat
               .filter((s) => s["KST Level 2"].toLowerCase() === category.toLowerCase())
               .filter((s) => verList.includes(s["Skill-Keywords"]))
               .map((s) => s["Skill-Keywords"])
         )
         .flat();
   }

   // lists to be displayed
   let acquiredSkillsFlattened = flattenSkills(acquiredSkillsCategories, false);
   let missingSkillsFlattened = flattenSkills(missingSkillsCategories, true);

   // boolean state to control skill toggle, threshold value
   const SKILL_COUNT_THRESH = 10;
   const [toggleAcquired, setToggleAcquired] = useState(acquiredSkillsFlattened.length > SKILL_COUNT_THRESH);
   const [toggleMissing, setToggleMissing] = useState(missingSkillsFlattened.length > SKILL_COUNT_THRESH);

   // functions to handle skill toggle button
   const handleToggleAcquired = () => setToggleAcquired(!toggleAcquired);
   const handleToggleMissing = () => setToggleMissing(!toggleMissing);

   // split list in two for fluid grid display
   let split1 = Math.ceil(acquiredSkillsFlattened.length / 2);
   let split2 = Math.ceil(missingSkillsFlattened.length / 2);

   let acquiredSkillsList = toggleAcquired
      ? [acquiredSkillsFlattened.slice(0, SKILL_COUNT_THRESH / 2), acquiredSkillsFlattened.slice(SKILL_COUNT_THRESH / 2, SKILL_COUNT_THRESH)]
      : [acquiredSkillsFlattened.slice(0, split1), acquiredSkillsFlattened.slice(split1, acquiredSkillsFlattened.length)];
   let missingSkillsList = toggleMissing
      ? [missingSkillsFlattened.slice(0, SKILL_COUNT_THRESH / 2), missingSkillsFlattened.slice(SKILL_COUNT_THRESH / 2, SKILL_COUNT_THRESH)]
      : [missingSkillsFlattened.slice(0, split2), missingSkillsFlattened.slice(split2, missingSkillsFlattened.length)];

   return (
      <AccordionItem borderColor='gray.400'>
         {({ isExpanded }) => (
            <>
               <AccordionButton bg={isExpanded ? "gray.200" : "unset"}>
                  <Grid flex='1' textAlign='left' templateColumns='1fr 8rem 3rem'>
                     <Box>
                        <Text fontWeight='500'>{noc + " - " + title}</Text>
                     </Box>
                  </Grid>
                  <AccordionIcon fontSize='3xl' />
               </AccordionButton>
               <AccordionPanel pb={12}>
                  <Box outline='#1a75ff solid 2px' bg='gray.200'>
                     <Grid templateColumns={{ base: "100%", lg: "repeat(3, 1fr)" }} spacing={6} p={2}>
                        <GridItem colSpan={1} mr={2}>
                           <Box align='start'>
                              <Text fontWeight='500'>Description</Text>
                              <Text fontSize='sm' as='span'>
                                 <Text as='span' mr={2}>
                                    {description}
                                 </Text>
                                 {isDescTooLong && (
                                    <Button variant='wrapper' size='sm' color='blue.500' mb={1} onClick={() => setExpandDesc((prevValue) => !prevValue)}>
                                       {expandDesc ? "Show Less" : "See More"}
                                    </Button>
                                 )}
                              </Text>
                           </Box>
                        </GridItem>
                        <GridItem colSpan={2}>
                           <Grid p={3} m={3} outline='#c2c2d6 solid 2px' borderRadius='25px' gap={2} templateColumns={{ base: "100%", md: "repeat(2,1fr)" }}>
                              <Box>
                                 <HStack justifyContent='space-between' pr={2} pl={2}>
                                    <Button size='sm' visibility='hidden'>
                                       +
                                    </Button>
                                    <Heading size='md' textAlign='center' mb={1}>
                                       Skills I have
                                    </Heading>
                                    {acquiredSkillsFlattened.length > SKILL_COUNT_THRESH ? (
                                       <Button variant='solid' size='sm' color='blue.500' onClick={handleToggleAcquired}>
                                          {toggleAcquired ? "+" : "-"}
                                       </Button>
                                    ) : (
                                       <Button size='sm' visibility='hidden'>
                                          +
                                       </Button>
                                    )}
                                 </HStack>
                                 <Grid p={2} gap={2} templateColumns={{ base: "100%", sm: "repeat(2,1fr)" }}>
                                    {acquiredSkillsList.map((skillsList, index) => skillsList.map((s) => <SkillItem key={index} label={s}></SkillItem>))}
                                 </Grid>
                              </Box>
                              <Box>
                                 <HStack justifyContent='space-between' pr={2} pl={2}>
                                    <Button size='sm' visibility='hidden'>
                                       +
                                    </Button>
                                    <Heading size='md' textAlign='center' mb={1}>
                                       Missing Skills
                                    </Heading>
                                    {missingSkillsFlattened.length > SKILL_COUNT_THRESH ? (
                                       <Button variant='solid' size='sm' color='blue.500' onClick={handleToggleMissing}>
                                          {toggleMissing ? "+" : "-"}
                                       </Button>
                                    ) : (
                                       <Button size='sm' visibility='hidden'>
                                          +
                                       </Button>
                                    )}
                                 </HStack>
                                 <Grid p={2} gap={2} templateColumns={{ base: "100%", sm: "repeat(2,1fr)" }}>
                                    {missingSkillsList.map((skillsList, index) => skillsList.map((s) => <SkillItem key={index} isMissing label={s}></SkillItem>))}
                                 </Grid>
                              </Box>
                           </Grid>
                        </GridItem>
                     </Grid>
                     <Box p={3}>
                        <Text fontWeight='700' mb={3}>
                           Job Titles
                        </Text>
                        <HStack wrap='wrap' spacing={0}>
                           {jobTitles.slice(0, 10).map((title) => (
                              <Tag textTransform='capitalize' mb={2} mr={2}>
                                 {title}
                              </Tag>
                           ))}
                        </HStack>
                     </Box>
                     <Box p={3}>
                        <Text fontWeight='700'>Main Duties</Text>
                        <UnorderedList>
                           {duties.slice(0, 5).map((item, index) => (
                              <ListItem key={index} textTransform='capitalize'>
                                 <p>{item}</p>
                              </ListItem>
                           ))}
                        </UnorderedList>
                     </Box>
                     {programs.length > 0 ? (
                        <Grid pt={10}>
                           <Heading size='sm' textAlign='center' mb={2}>
                              Skill Development Opportunities
                           </Heading>
                           {!isFetchingCourseMatches && programs ? (
                              <Accordion allowToggle>
                                 {programs.map((program) => (
                                    <ProgramAccordionItem key={program._id} program={program} />
                                 ))}
                              </Accordion>
                           ) : (
                              <Flex justify='center'>
                                 <Text as='span' mr={2}>
                                    Searching{" "}
                                 </Text>{" "}
                                 <Spinner size='lg' />
                              </Flex>
                           )}
                        </Grid>
                     ) : (
                        ""
                     )}
                  </Box>
               </AccordionPanel>
            </>
         )}
      </AccordionItem>
   );
};

export default OccupationItem;
