import React, { useState, useEffect } from "react";
import { useCombobox } from "downshift";
import { SearchIcon } from "@chakra-ui/icons";
import { Box, Button, Grid, Input, InputGroup, InputLeftElement, VStack } from "@chakra-ui/react";

/*
   ---- Functionality ----
   clearOnSubmit: boolean
   items: array
   itemsToString: function that takes in one of the items and returns a string representation of it
   handleSubmit: function that recieves the string submitted by either clicking a suggestion, pressing the button or pressing enter

   -------- Input --------
   searchIcon: boolean
   placeholder: string

   -------- Button --------
   showButton: boolean
   button: {
      colorScheme: ChakaraColorScheme,
      text: string
   }

   ------- Styles -------
   borderRadius: ChakaraBorderRadius
   size: ChakaraSize
   styleProps: any additional props to be applied to the Autocomplete parent element

*/

const Autocomplete = ({ clearOnSubmit, searchIcon,_id, inputLabel, stackLabel, borderRadius, placeholder, size, showButton, button, items, itemToString, handleSubmit, handleOnChange, ...styleProps }) => {
   let itemStrings = items.map((item) => itemToString(item));
   const [inputItems, setInputItems] = useState(itemStrings);
   const [stayOpen, setStayOpen] = useState(true)

   function stateReducer(_, actionAndChanges) {
      const { type, changes } = actionAndChanges;
      switch (type) {
         case useCombobox.stateChangeTypes.InputChange:
            setStayOpen(true)
            if (handleOnChange) handleOnChange(changes.inputValue);
            return changes;
         case useCombobox.stateChangeTypes.InputKeyDownEnter:
         case useCombobox.stateChangeTypes.ItemClick:
            handleSubmit(changes.inputValue);
            setStayOpen(false)
            return clearOnSubmit
               ? {
                    ...changes,
                    inputValue: "",
                 }
               : changes;
         case useCombobox.stateChangeTypes.InputBlur:
            return changes;
         default:
            return changes;
      }
   }

   const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, setInputValue, inputValue, openMenu } = useCombobox({
      items: inputItems,
      stateReducer,
   });

   useEffect(() => {
      let itemStrings = items.map((item) => itemToString(item));
      setInputItems(itemStrings.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase())));
   }, [items, inputValue, itemToString])

   const handleButton = () => {
      handleSubmit(inputValue);
      if (clearOnSubmit) setInputValue("");
   };

   return (
      <Box style={{ position: "relative" }} {...getComboboxProps()} {...styleProps}>
         <Grid templateColumns={{ base: "100%", sm: "1fr max-content"}}>
            <InputGroup>
               {searchIcon && <InputLeftElement height='100%' pointerEvents='none' children={<SearchIcon fontSize={size} />} />}
               <Input {...getInputProps()} id={_id} aria-labelledby={inputLabel} borderRadius={showButton ? "none" : borderRadius} size={size} placeholder={placeholder} onClick={() => setStayOpen(true)} borderStartRadius={borderRadius} onFocus={() => {
                  openMenu()
                  setStayOpen(true)
               }}/>
            </InputGroup>
               {showButton && (
                  <Button onClick={handleButton} colorScheme={button.colorScheme || "blue"} borderRadius='none' px={8} size={size} borderEndRadius={borderRadius}>
                     {button.text || "Submit"}
                  </Button>
               )}
            </Grid>
         <VStack
            
            as='ul'
            sx={{ listStyle: "none" }}
            {...getMenuProps({}, { suppressRefError: true })}
            boxShadow='rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
            textAlign='start'
            position='absolute'
            top='100%'
            width='100%'
            aria-labelledby={stackLabel}
            maxH='20rem'
            overflow='auto'
            bg='white'
            zIndex={100}
         >

            {(isOpen && stayOpen && items.length === 0) &&
               <Box
                  as='li'
                  fontSize='md'
                  p={2}
                  width='100%'
                  bg="white"
                  textTransform="capitalize"
               >
                  Loading items … please wait …
               </Box>
            }

            {(isOpen && stayOpen && items.length > 0 && inputValue.length > 0 && inputItems.length === 0) &&
               <Box
                  as='li'
                  fontSize='md'
                  p={2}
                  width='100%'
                  bg="white"
                  textTransform="capitalize"
               >
                  No results for "{inputValue}"
               </Box>
            }

            {(isOpen && placeholder === 'Search Location' && stayOpen && inputItems.length > 0) &&
               inputItems.map((item, index) => ( //For Dashboard
                  <Box
                     as='li'
                     key={`${item}${index}`}
                     {...getItemProps({ index, item })}
                     fontSize='md'
                     p={2}
                     width='100%'
                     bg={highlightedIndex === index ? "gray.100" : "white"}
                     _hover={{ bg: "gray.100" }}
                     textTransform="capitalize"
                  >
                     {item}
                  </Box>
            ))}
            {(isOpen && placeholder !== 'Search Location' && stayOpen && inputItems.length > 0) &&
               inputItems.slice(0, 120).map((item, index) => (
                  <Box
                     as='li'
                     key={`${item}${index}`}
                     {...getItemProps({ index, item })}
                     fontSize='md'
                     p={2}
                     width='100%'
                     bg={highlightedIndex === index ? "gray.100" : "white"}
                     _hover={{ bg: "gray.100" }}
                     textTransform="capitalize"
                  >
                     {item}
                  </Box>
            ))}
         </VStack>
      </Box>
   );
};

export default Autocomplete;
