import React, { useState } from "react";

import SkillsGatherer from "./SkillsGatherer";
import SkillsResults from "./SkillsResults";

const SkillsAssessment = () => {
   const [showSkillGatherer, setShowSkillGatherer] = useState(true);

   const showGatherer = () => {
      setShowSkillGatherer(true);
   };

   const showResults = () => {
      setShowSkillGatherer(false);
   };

   return showSkillGatherer ? <SkillsGatherer showResults={showResults} /> : <SkillsResults showGatherer={showGatherer} />;
};

export default SkillsAssessment;
