import React, { useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import { Box, Center, Grid, HStack, Spinner, Text } from "@chakra-ui/react";
import useWindowDimensions from "./useWindowDimensions";

import ontarioCities from "./data/ontarioCities";
import mapStyles from "./data/mapStyles";
import mapPoly from "./mapStyle/mapData";

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const getCityCoor = (cityName) => {
  return ontarioCities.filter((city) => city.city === cityName)?.[0];
};

const Map = ({ city, setCity, cityData, skill, occupation, cityDeselectFlag, setCityDeselectFlag, selectedID, setSelectedID, handleApiLoaded, mapRef }) => {
 
   const [bounds, setBounds] = useState(null);
   const [zoom, setZoom] = useState(7);
   const [searchZoom, setSearchZoom] = useState(5);
   const maxZoom = 11;
   const defaultZoom = 7.8;




   

   let clusterData = cityData
      ? cityData
           ?.filter((city) => !!getCityCoor(city._id))
           ?.map((city, index) => {
              const cityCoor = getCityCoor(city._id);
              return {
                 type: "Feature",
                 properties: {
                    cluster: false,
                    location_id: index + 1,
                    total: city.total,
                    name: city._id,
                 },
                 geometry: {
                    type: "Point",
                    coordinates: [cityCoor.lng, cityCoor.lat],
                 },
              };
           })
      : [];

  

   const { clusters, supercluster } = useSupercluster({
      points: clusterData,
      bounds,
      zoom,
      options: {
         radius: 100,
         maxZoom: 24,
         map: (props) => ({
            total: props.total,
         }),
         reduce: (accumulated, props) => {
            accumulated.total = accumulated.total += props.total;
         },
      },
   });

   const { clusters: singleCluster, supercluster: singleSupercluster } = useSupercluster({
     points: clusterData,
     bounds,
     zoom,
     options: {
       radius: 0,
       maxZoom: 24,
       map: (props) => ({
         total: props.total,
       }),
       reduce: (accumulated, props) => {
         accumulated.total = accumulated.total += props.total;
       },
     },
   });
   

   const maxTotal = Math.max.apply(
      Math,
      clusterData.map((city) => {
         return city.properties.total;
      })
   );

   const onLocationSelect = (locationId) => {
      let cityDetails = clusterData.filter((city) => city.properties.location_id === locationId);
      let cityName = cityDetails?.[0]?.properties?.name;
      let cityLatitude=cityDetails?.geometry?.coordinates[1];
      let cityLongitude = cityDetails?.geometry?.coordinates[0];
      setCity(cityName);
      setSelectedID(locationId);
      // mapRef.current.panTo({ lat: cityLatitude, lng: cityLongitude });
      window.scrollTo({
         top: 250,
         behavior: "smooth",
      });

   };

   if (cityDeselectFlag) {
      mapRef.current.setZoom(defaultZoom);
      mapRef.current.panTo({ lat: 44.65, lng: -77.5 });
      setCityDeselectFlag(false);
   }



   const LegendItem = ({ color, max, min }) => (
     <HStack mb="5px">
       <Box
         onClick={() => ''}
         width="2rem"
         height="2rem"
         bg={color}
         boxShadow="base"
         borderRadius="sm"
       />
       <Text>{max ? `< ${max}` : `>= ${min}`} </Text>
     </HStack>
   );

   const Marker = ({ children }) => children;

   const MarkerObj = ({ locationID, latitude, longitude, markerSize, color, fontColor, total, fontSize, name }) => {
     const [isInCircle, setIsInCircle] = useState(false);
      return (
        <Center
          onMouseOver={() => setIsInCircle(true)}
          onMouseOut={() => setIsInCircle(false)}
          position="absolute"
          width={`${markerSize}px`}
          height={`${markerSize}px`}
          transform="translate(-50%, -50%)"
          background={color}
          color={fontColor}
          fontSize={fontSize}
          borderRadius="full"
          onClick={(e) => {
            e.stopPropagation();
            onLocationSelect(locationID);
            mapRef.current.panTo({ lat: latitude, lng: longitude });
            zoom + 1 <= maxZoom && mapRef.current.setZoom(zoom+1);
          }}
          _hover={{
            cursor: "pointer",
          }}
        >
          {isInCircle ?  <Text position="absolute" top="-30%">{name}</Text> : ''}
          {total}
        </Center>

      );
   };

   const ClusterObj = ({ clusterID, latitude, longitude, markerSize, total, color, fontColor, fontSize}) => {
     const [isInCircle, setIsInCircle] = useState(false);
      return (
         <Center
            onMouseEnter={() => setIsInCircle(true)}
            onMouseLeave={() => setIsInCircle(false)}
            position='absolute'
            width={`${markerSize}px`}
            height={`${markerSize}px`}
            transform='translate(-50%, -50%)'
            background={color}
            color={fontColor}
            fontSize={fontSize}
            borderRadius='full'
            onClick={() => {
               // zoom into cluster on click
               const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterID), 20);
               mapRef.current.setZoom(expansionZoom);
               mapRef.current.panTo({ lat: latitude, lng: longitude });

            }}
            _hover={{
               cursor: "pointer",
            }}
         >
            {isInCircle ?  <Text position="absolute"   width='120px' top="-30%">Zoom in for cities/ communities</Text> : ''}
            {total}
         </Center>
      );
   };

   return (
     <Box
       height="100%"
       width="100%"
       position="relative"
       onClick={() =>
         window.scrollTo({
           top: 64,
           behavior: "smooth",
         })
       }
     >
       {!cityData && (
         <Box
           position="absolute"
           top="50%"
           left="49%"
           marginRight="-50%"
           zIndex={3}
         >
           <Spinner label="Loading Map Data" size="xl" />
         </Box>
       )}
       <Box
         position="absolute"
         top={0}
         left={0}
         zIndex={3}
         bg="white"
         borderRadius="md"
         p={2}
         m={4}
         boxShadow="base"
       >
         <Text display="inline-block">Job Market</Text>
         {skill && (
           <Text display="inline-block" textTransform="capitalize">
             &nbsp;for {skill}
           </Text>
         )}
         {occupation && (
           <Text display="inline-block" textTransform="capitalize">
             &nbsp;for {occupation}
           </Text>
         )}
       </Box>
       <Box
         width="15rem"
         position="absolute"
         bottom={2}
         right={12}
         zIndex={3}
         bg="white"
         borderRadius="md"
         py={2}
         px={4}
         m={4}
         boxShadow="base"
       >
         <Text mb={2} fontWeight="bold" textAlign="left">
           Number of Job Postings
         </Text>
         <Grid templateColumns="6rem 6rem">
           <LegendItem color="#ffff00" max={100} />
           <LegendItem color="#ffce00" max={200} />
           <LegendItem color="#ff9a00" max={500} />
           <LegendItem color="#ff5a00" min={500} />
         </Grid>
       </Box>
       <GoogleMapReact
         bootstrapURLKeys={{ key: GOOGLE_KEY }}
         defaultCenter={{ lat: 44.65, lng: -77.5 }}
         defaultZoom={defaultZoom}
         onChange={({ zoom, bounds }) => {
           setZoom(zoom);
           setBounds([
             bounds.nw.lng,
             bounds.se.lat,
             bounds.se.lng,
             bounds.nw.lat,
           ]);
         }}
         yesIWantToUseGoogleMapApiInternals
         onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
         options={{
           styles: mapStyles,
         }}
       >
         {!city ? (

           clusters.map((cluster) => {
             
             
               const [longitude, latitude] = cluster.geometry.coordinates;
               const {
                 cluster: isCluster,
                 point_count: pointCount,
                 total,
                 name,
                 location_id: locationID,
               } = cluster.properties;

               let fontColor = "black";
               let fontSize = "15px";
               let color;

               if (total < 100) {
                 color = "rgba(255, 255, 0, 0.40)";
               } else if (total < 200) {
                 color = "rgba(255, 206, 0, 0.40)";
               } else if (total < 500) {
                 color = "rgba(255, 154, 0, 0.40)";
               } else {
                 color = "rgba(255, 90, 0, 0.40)";
               }

               let markerSize;
               markerSize = 50;
               return isCluster ? (
                 <Marker
                   key={`cluster-${cluster.id}`}
                   lat={latitude + 0.03}
                   lng={longitude}
                 >
                   <ClusterObj
                     clusterID={cluster.id}
                     latitude={latitude}
                     longitude={longitude}
                     pointCount={pointCount}
                     markerSize={markerSize}
                     total={total}
                     color={color}
                     fontColor={fontColor}
                     fontSize={fontSize}
                   />
                 </Marker>
               ) : (
                 <Marker key={locationID} lat={latitude + 0.03} lng={longitude}>
                   <MarkerObj
                     locationID={locationID}
                     latitude={latitude}
                     longitude={longitude}
                     total={total}
                     markerSize={markerSize}
                     color={color}
                     fontColor={fontColor}
                     fontSize={fontSize}
                     name={name}
                   />
                 </Marker>
               );
          //    })
          //  : singleClusterData.map((cluster) => {
          //      const [longitude, latitude] = cluster.geometry.coordinates;
          //      const {
          //        cluster: isCluster,
          //        point_count: pointCount,
          //        total,
          //        name,
          //        location_id: locationID,
          //      } = cluster.properties;

          //      let fontColor = "black";
          //      let fontSize = "15px";
          //      let color;

          //      if (total < 100) {
          //        color = "rgba(255, 255, 0, 0.40)";
          //      } else if (total < 200) {
          //        color = "rgba(255, 206, 0, 0.40)";
          //      } else if (total < 500) {
          //        color = "rgba(255, 154, 0, 0.40)";
          //      } else {
          //        color = "rgba(255, 90, 0, 0.40)";
          //      }

          //      let markerSize;
          //      markerSize = 50;
          //      return (


          //        <Marker key={locationID} lat={latitude + 0.03} lng={longitude}>
          //          <MarkerObj
          //            locationID={locationID}
          //            latitude={latitude}
          //            longitude={longitude}
          //            total={total}
          //            markerSize={markerSize}
          //            color={color}
          //            fontColor={fontColor}
          //            fontSize={fontSize}
          //            name={name}
          //          />
          //        </Marker>
          //      )
             })): ( singleCluster.filter((cluster1)=>cluster1.properties.location_id===selectedID).map((cluster) =>{
             const [longitude, latitude] = cluster.geometry.coordinates;
               const {
                 total,
                 name,
                 location_id: locationID,
               } = cluster.properties;

               let fontColor = "black";
               let fontSize = "15px";
               let color;

               if (total < 100) {
                 color = "rgba(255, 255, 0, 0.40)";
               } else if (total < 200) {
                 color = "rgba(255, 206, 0, 0.40)";
               } else if (total < 500) {
                 color = "rgba(255, 154, 0, 0.40)";
               } else {
                 color = "rgba(255, 90, 0, 0.40)";
               }

               let markerSize;
               markerSize = 50;
               return  (


                 <Marker key={locationID} lat={latitude + 0.03} lng={longitude}>
                   <MarkerObj
                     locationID={locationID}
                     latitude={latitude}
                     longitude={longitude}
                     total={total}
                     markerSize={markerSize}
                     color={color}
                     fontColor={fontColor}
                     fontSize={fontSize}
                     name={name}
                   />
                 </Marker>
               )}))}
       </GoogleMapReact>
     </Box>
   );
};

export default Map;
