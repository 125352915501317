import React, {useRef, useState } from "react";
import {
  Grid,
  GridItem,
  Box,
  Text,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  RadioGroup,
  Stack,
  Radio,
  Button,
  Checkbox,
  VStack,
  Heading
} from "@chakra-ui/react";

import { useAlert } from "react-alert";

import Autocomplete from "../../components/Autocomplete";
import {
  useOccupations,
  useSkills,
  useJobPostingsInCities,
  useActiveJobPostings,
  useUpdatedDate,
} from "../../api";

import Map from "./Map";
import Data from "./Data";
import { FaFilter } from "react-icons/fa";
import { HiChevronDoubleLeft } from "react-icons/hi";

import "./sidebar/Sidebar.css";
import { useJobPostingsNumbers, useNocToTitle } from "../../api";
import PeriodSelector from "./controls/PeriodSelector";
import MultiSelect from "react-multi-select-component";
import mapPoly from "./mapStyle/mapData";

import ontarioCities from "./data/ontarioCities";

const getCityCoor = (cityName) => {
  return ontarioCities.filter((city) => city.city === cityName)?.[0];
};

const Dashboard = () => {
  const [city, setCity] = useState("");
  const defaultLatitude = 44.65;
  const [selectedLatitude, setSelectedLatitude] = useState(defaultLatitude);
  const defaultLongitude = -77.5;
  const [selectedLongitude, setSelectedLongitude] = useState(defaultLongitude);
  const [selectedID, setSelectedID] = useState();
  const [occupation, setOccupation] = useState(null);
  const [skill, setSkill] = useState("");
  const [showSidebar, setShowSidebar] = useState(true);
  const [mapData, setmapData] = useState("1");
  const [selectedOccupations, setSelectedOccupations] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [period, setPeriod] = useState("7 Days");
  const [cityDeselectFlag, setCityDeselectFlag] = useState(false);

  const { data: occupations } = useOccupations();
  const { data: lastDate } = useUpdatedDate();

  const [skillMultiSelect, setSkillMultiSelect] = useState([]);

  const [skillsSelected, setSkillsSelected] = useState([]);

  const [occupationMultiSelect, setOccupationMultiSelect] = useState([]);

  const [occupationsSelected, setOccupationsSelected] = useState([]);

  const [checkedOccs, setCheckedOccs] = useState([])
  const [checkedSkills, setCheckedSkills] = useState([])

  let year = lastDate ? lastDate[0].post_date.substring(2, 4) : "00";
  let month = lastDate ? lastDate[0].post_date.substring(5, 7) : "00";
  let date = lastDate ? lastDate[0].post_date.substring(8, 10) : "00";

  const { data: activeJobPostings, isFetching: isFetchingActiveJobPostings } =
    useActiveJobPostings();
  const { data: skills } = useSkills();
  const { data: cityData } = useJobPostingsInCities(
    mapData === "1" ? selectedOccupations.map((e) => e.NOC) : [],
    mapData === "2" ? selectedSkills : []
  );

  let occupationList = !occupations ? [] : occupations;
  let skillList = !skills ? [] : skills;

  let citiesWithJobPostings = [];
  {
    if (activeJobPostings) {
      citiesWithJobPostings = activeJobPostings.map((posting) =>
        posting["city"].toLowerCase()
      );
    }
  }

  const mapRef = useRef();

   const handleApiLoaded = (map, maps) => {
     mapRef.current = map;
     mapPoly(map, maps);
   };

   let clusterData = cityData
     ? cityData
         ?.filter((city) => !!getCityCoor(city._id))
         ?.map((city, index) => {
           const cityCoor = getCityCoor(city._id);
           return {
             type: "Feature",
             properties: {
               cluster: false,
               location_id: index + 1,
               total: city.total,
               name: city._id,
             },
             geometry: {
               type: "Point",
               coordinates: [cityCoor.lng, cityCoor.lat],
             },
           };
         })
     : [];

  

  let cityList = [...new Set(citiesWithJobPostings)].sort();

  const handleOccupationSelect = (input) => {
    if (selectedOccupations.length > 9) {
      return;
    }
    if (occupationList.find((occupation) => occupation?.Title === input)) {
      if (
        selectedOccupations.find((occupation) => occupation?.Title === input)
      ) {
        return;
      }
      setSelectedOccupations([
        ...selectedOccupations,
        occupationList.find((occupation) => occupation?.Title === input),
      ]);
      setOccupationMultiSelect([
        ...occupationMultiSelect,
         {label: String(occupationList.find((occupation) => occupation?.Title === input).Title), value: occupationList.find((occupation) => occupation?.Title === input)},
      ]);
      setCheckedOccs([
        ...checkedOccs,
        true
      ]);
    }
  };

  const handleSkillSelect = (input) => {
    if (selectedSkills.length > 9) {
      return;
    }
    if (skillList.find((skill) => skill["Skill-Keywords"] === input)) {
      if (selectedSkills.indexOf(input) !== -1) {
        return;
      }
      setSelectedSkills([
        ...selectedSkills,
        skillList.find((skill) => skill["Skill-Keywords"] === input)[
          "Skill-Keywords"
        ],
      ]);
      setSkillMultiSelect([
        ...skillMultiSelect,
        {label: String(skillList.find((skill) => skill["Skill-Keywords"] === input)["Skill-Keywords"]),value: skillList.find((skill) => skill["Skill-Keywords"] === input)["Skill-Keywords"]},
      ]);
      setCheckedSkills([
        ...checkedSkills,
        true
      ]);
      console.log(skillMultiSelect)
    }
  };

  const handleCityDeselect = () => {
    setCity("");
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    setCityDeselectFlag(true);
    console.log(city);
  };

  const clearFilters = () => {
    setSelectedOccupations([]);
    setSelectedSkills([]);
    setCity("");
    setCityDeselectFlag(true);
    setSkillMultiSelect([]);
    setOccupationMultiSelect([]);
  };

  const setCityFromSearchBar = (input) => {
    let cityName = cityData.filter(
      (city) => city._id.toLowerCase() === input.toLowerCase()
    )[0]?._id;
  let locationID = clusterData.filter(
    (city) => city.properties.name === cityName
  )?.[0].properties.location_id;
    setCity(cityName);
    setSelectedID(locationID);
      let cityDetails = clusterData.filter(
        (city) => city.properties.name === cityName
      );
     
      let cityLatitude = cityDetails?.geometry?.coordinates[1];
      let cityLongitude = cityDetails?.geometry?.coordinates[0];

      

    console.log(clusterData);
  };


  const toggleSkillsList = (value, skill) => {
    value ? addSelectedSkill(skill) : removeSelectedSkill(skill)
  }

  const addSelectedSkill = (skill) => {
    setSelectedSkills([...selectedSkills, skill])
  }

  const removeSelectedSkill = (skill) => {
    const temp = selectedSkills.filter((s) => s !== skill)
    setSelectedSkills(temp)
  }

  const toggleOccupationList = (value, occ) => {
    value ? addSelectedOccupation(occ) : removeSelectedOccupation(occ)
  }

  const addSelectedOccupation = (occ) => {
    setSelectedOccupations([...selectedOccupations, occ])
  }

  const removeSelectedOccupation = (occ) => {
    const temp = selectedOccupations.filter((o) => o._id !== occ._id)
    setSelectedOccupations(temp)
  }

  const togglecCheckedOccs = (idx) => {
    let copy = [...checkedOccs]
    copy[idx] = ! copy[idx]
    setCheckedOccs(copy)
  }

  const togglecCheckedSkills = (idx) => {
    let copy = [...checkedSkills]
    copy[idx] = ! copy[idx]
    setCheckedSkills(copy)
  }

  const DashboardHeader = () => {
    return (
      <Grid padding="0.2rem 1rem" templateColumns={{ base: "1fr", lg: "1fr 1fr" }} height={{ base: "max-content", lg: "3.2rem" }} alignItems="center" background="rgb(240, 240, 240)">
        <Text fontWeight="bold" fontSize="1rem">
          {city
            ? ` ${city} Job Market`
            : "Select a city or community by clicking on the map or searching in the filters bar"}
        </Text>
        <Text align="center" fontSize="sm">* Please visit Job Seekers tool for job postings.</Text>
      </Grid>
    );
  };

  const Sidebar = () => {
    return (
      <Box padding="0 40px" pt={{ base: "0", lg: "30px" }} zIndex={5} position={{ base: "relative", lg: "sticky" }} top={{ base: "0", lg: "3.2rem" }}>
        <Grid templateRows={{ base: "max-content max-content", lg: "40rem max-content" }}>
          <Box>
            <Grid justifyContent={{ base: "start", lg: "end" }} >
              <Text
                cursor="pointer"
                fontSize="0.82rem"
                variant="outline"
                onClick={clearFilters}
              >
                Clear Filters
              </Text>
            </Grid>

            <Box pt="1.5rem" pb="0.5rem">
              <Text fontSize="0.9rem" fontWeight="bold">
                City or Community
              </Text>
            </Box>
            {city ? (
              <Tag maxWidth="12rem" height="1rem" isTruncated fontSize="md">
                <TagLabel>{city}</TagLabel>
                <TagCloseButton onClick={handleCityDeselect} />
              </Tag>
            ) : (
              <Autocomplete
                width="12rem"
                _id="Location_Input"
                inputLabel="Location_Input"
                handleSubmit={(input) => setCityFromSearchBar(input)}
                placeholder="Search Location"
                size="sm"
                clearOnSubmit
                items={cityList}
                itemToString={(item) => item}
              />
            )}

            <RadioGroup mt="20px" mb="15px" onChange={setmapData} value={mapData}>
              <Stack direction="row">
                <Radio value="1">Occupations</Radio>
                <Radio value="2">Skills</Radio>
              </Stack>
            </RadioGroup>

            {mapData === "1" && (
              <div>
                <Autocomplete
                  width="12rem"
                  _id="Occupation_Input"
                  inputLabel="Occupation_Input"
                  handleSubmit={handleOccupationSelect}
                  placeholder="Search Occupations"
                  size="sm"
                  clearOnSubmit
                  items={occupationList}
                  itemToString={(item) => item.Title}
                />

                <VStack
                  alignItems="start"
                  m={2}
                  overflow="auto"
                  maxHeight="22rem"
                >
                  {occupationMultiSelect.map((occ, index) => (
                    <Checkbox
                      size="md"
                      mt={1}
                      isChecked={checkedOccs[index]}
                      onChange={(e) =>
                        {
                        toggleOccupationList(e.target.checked, occ.value)
                        togglecCheckedOccs(index)
                      }}
                    >
                      {occ.label}
                    </Checkbox>
                  ))}
                </VStack>
              </div>
            )}
            {mapData === "2" && (
              <div>
                <Autocomplete
                  width="12rem"
                  handleSubmit={handleSkillSelect}
                  placeholder="Search Skills"
                  size="sm"
                  items={skillList}
                  clearOnSubmit
                  itemToString={(item) => item["Skill-Keywords"]}
                />

                <VStack
                  alignItems="start"
                  m={2}
                  overflow="auto"
                  maxHeight="22rem"
                >
                  {skillMultiSelect.map((skill, index) => (
                    <Checkbox
                      size="md"
                      mt={1}
                      isChecked={checkedSkills[index]}
                      onChange={(e) =>
                        {
                        toggleSkillsList(e.target.checked, skill.label)
                        togglecCheckedSkills(index)
                      }}
                    >
                      {skill.label}
                    </Checkbox>
                  ))}
                </VStack>
              </div>
            )}
          </Box>
        </Grid>
      </Box>
    );
  }

  return (
    <div>
      <Grid templateRows={{ base: "max-content max-content", lg: "3.2rem max-content" }} position="relative">
        <Box zIndex={5} position="sticky" top={0}>
          <DashboardHeader />
        </Box>
        <Grid templateColumns={{ base: "1fr", lg: "17rem auto" }} position="relative">
          <Grid position="sticky" top="0" right="0" zIndex="100" background="white">
            <Box>
              <Sidebar />
            </Box>
          </Grid>
          <Grid>
            <Box height="64vh" top="0rem" zIndex={1}>
              <Map
                city={city}
                setCity={setCity}
                selectedID={selectedID}
                setSelectedID={setSelectedID}
                cityData={cityData}
                skill={skill}
                occupation={occupation?.Title}
                cityDeselectFlag={cityDeselectFlag}
                setCityDeselectFlag={setCityDeselectFlag}
                handleApiLoaded={handleApiLoaded}
                mapRef={mapRef}
              />
            </Box>
            <Box zIndex={4}>
              <Data
                year={year}
                month={month}
                date={date}
                period={period}
                setPeriod={setPeriod}
                city={city}
                selectedOccupations={selectedOccupations}
                selectedSkills={selectedSkills}
                mapData={mapData}
                occupation={occupation}
                skill={skill}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
