import React, { useState, createContext, useContext } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [userSkills, setUserSkills] = useState([]);
  const [userEducation, setUserEducation] = useState([]);
  const [userJobs, setUserJobs] = useState([]);
  const [savedPostings, setSavedPostings] = useState([]);
  const [sortedOccupations, setSortedOccupations] = useState([]);

  const isPostingSaved = (newPostingId) => {
    return (
      [...savedPostings].filter((postingId) => postingId === newPostingId)
        .length > 0
    );
  };

  const sortOccupations = (sortedOccs) => {
    setSortedOccupations(sortedOccs);
  };

  const savePosting = (newPostingId) => {
    setSavedPostings([...savedPostings, newPostingId]);
  };

  const unsavePosting = (newPostingId) => {
    setSavedPostings([
      ...savedPostings.filter((postingId) => postingId !== newPostingId),
    ]);
  };

  const addSkills = (newSkills, from) => {
    let skills = [...userSkills];
    let skillIds = skills.map(e => e._id);
    let skillsToAdd = []

    // Adds new skills to userSkills array
    newSkills.forEach(skill => {
      // Checks to see if user already has skill
      let skillIndex = skillIds.indexOf(skill._id);
      if (skillIndex === -1) {
        // If skill not yet in userSkills
        // Check if it is also in this batch of skills to add more than once
        let isInSkillsToAdd = skillsToAdd.map(e => e._id).indexOf(skill._id) !== -1
        if (!isInSkillsToAdd) {
          // Add data to skillsToAdd array
          let newSkill = { ...skill, from: [from] };
          newSkill.job = skill.job ? [skill.job] : [];
          newSkill.education = skill.education ? [skill.education] : [];
          skillsToAdd = [...skillsToAdd, newSkill]
        }
      } else {
        // If user has skill simply add where it was also added from
        // This is to track if a skill is added through multiple methods such as
        // through resume and education experienece
        let oldSkill = skills[skillIndex]
        let newSkill = { ...oldSkill, from: [...new Set([...oldSkill.from, from])] }
        if (skill.job) newSkill.job = [...oldSkill.job, skill.job];
        if (skill.education) newSkill.education = [...oldSkill.education, skill.education];
        skills[skillIndex] = newSkill;
      }
    });

    setUserSkills([...skills, ...skillsToAdd]);
  };

  const removeSkill = (skillId) => {
    setUserSkills([...userSkills.filter((skill) => skill._id !== skillId)]);
  };

  const clearSkills = () => {
    setUserSkills([]);
  };

  const addEducation = (newEducation) => {
    if (userEducation.map(e => e._id).indexOf(newEducation._id) === -1) {
      setUserEducation([...userEducation, newEducation]);
    }
  };

  const removeEducation = (index) => {
    let newEducation = [...userEducation];
    let programId = newEducation[index]._id;

    let newSkills = [...userSkills];
    for (let i = 0; i < newSkills.length; i++) {
      if (newSkills[i].education.includes(programId)) {
        newSkills[i].education = newSkills[i].education.filter(id => id !== programId);
        if (newSkills[i].education.length === 0) {
          newSkills[i] = { ...newSkills[i], from: newSkills[i].from.filter(e => e !== "education")}
        }
      }
    }

    newSkills = newSkills.filter(skill => skill.from.length > 0);
    setUserSkills(newSkills);
    newEducation.splice(index, 1)
    setUserEducation([ ...newEducation]);
  };

  const addJob = (newJob) => {
    if (userJobs.map(e => e._id).indexOf(newJob._id) === -1) {
      setUserJobs([...userJobs, newJob]);
    }
  };

  const removeJob = (index) => {
    let newJobs = [...userJobs];
    let jobId = newJobs[index]._id;
    
    let newSkills = [...userSkills];
    for (let i = 0; i < newSkills.length; i++) {
      if (newSkills[i].job.includes(jobId)) {
        newSkills[i].job = newSkills[i].job.filter(id => id !== jobId);
        if (newSkills[i].job.length === 0) {
          newSkills[i] = { ...newSkills[i], from: newSkills[i].from.filter(e => e !== "job")}
        }
      }
    }

    newSkills = newSkills.filter(skill => skill.from.length > 0);
    setUserSkills(newSkills);
    newJobs.splice(index, 1)
    setUserJobs([ ...newJobs]);
  };

  return (
    <UserContext.Provider
      value={{
        userSkills,
        addSkills,
        removeSkill,
        clearSkills,
        isPostingSaved,
        savePosting,
        unsavePosting,
        userJobs,
        addEducation,
        removeEducation,
        userEducation,
        addJob,
        removeJob,
        sortedOccupations,
        sortOccupations,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const {
    userSkills,
    addSkills,
    removeSkill,
    clearSkills,
    isPostingSaved,
    savePosting,
    unsavePosting,
    userEducation,
    userJobs,
    addEducation,
    removeEducation,
    addJob,
    removeJob,
    sortedOccupations,
    sortOccupations,
  } = useContext(UserContext);
  return {
    userSkills,
    addSkills,
    removeSkill,
    clearSkills,
    isPostingSaved,
    savePosting,
    unsavePosting,
    userEducation,
    userJobs,
    addEducation,
    removeEducation,
    addJob,
    removeJob,
    sortedOccupations,
    sortOccupations,
  };
};
