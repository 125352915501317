import React from "react";
import { Bar } from "react-chartjs-2";

const BarGraph = ({ data }) => {
  const { labels, totals } = data;

  return (
    <Bar
      data={{
        labels: labels,
        datasets: [
          {
            label: "Jobs",
            data: totals,
            backgroundColor: totals.map(() => "rgba(54, 162, 235, 0.2)"),
            borderColor: totals.map(() => "rgba(54, 162, 235, 1)"),
            borderWidth: 1,
          },
        ],
      }}
      options={{
        layout: {
          padding: {
            top: 50,
            left: 15,
            right: 15,
            bottom: 15,
          },
        },
        tooltips: {
          yAlign: "bottom",
          backgroundColor: "rgba(128, 128, 128, 1)",
        },
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontSize: 16,
                fontColor: "#010101",
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 20,
                fontColor: "#010101",
              },
              scaleLabel: {
                display: false,
                labelString: "Cities",
                fontSize: 30,
                fontColor: "#010101",
              },
            },
          ],
        },
      }}
    />
  );
};

export default BarGraph;
