import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import Card from "../../components/Card";
import BarGraph from "./charts/BarGraph";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button,
  Center,
  Grid,
  Box,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Text,
  HStack,
  useControllableState,
} from "@chakra-ui/react";

const ChartTesting = () => {
  return (
    <div>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <Accordion allowToggle>
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <SkillItemDropdown
              title="words words words words words words words words words"
              labels={["1", "2"]}
            ></SkillItemDropdown>
          </Grid>
        </Accordion>
      </Grid>
    </div>
  );
};

export default ChartTesting;

const SkillItemDropdown = ({ isMissing, title, labels }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          bg={`${isMissing ? "red" : "green"}.100`}
          color={`${isMissing ? "red" : "green"}.900`}
          _hover={{ background: `${isMissing ? "red" : "green"}.100` }}
          fontSize="sm"
          fontWeight="600"
          borderRadius="md"
        >
          <Box flex="1" textAlign="left">
            {title.replace(/_/g, " ")}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        {isMissing
          ? labels.map((label) => (
              <SkillItem isMissing label={label}></SkillItem>
            ))
          : labels.map((label) => <SkillItem label={label}></SkillItem>)}
      </AccordionPanel>
    </AccordionItem>
  );
};

const SkillItem = ({ isMissing, label }) => {
  return (
    <Center
      mb={2}
      height="2.4rem"
      bg={`${isMissing ? "red" : "green"}.100`}
      color={`${isMissing ? "red" : "green"}.900`}
      fontSize="sm"
      fontWeight="600"
      borderRadius="md"
    >
      {label}
    </Center>
  );
};
