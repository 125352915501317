import React, { useState, useEffect } from "react";
import { Box, Grid, Heading, Spinner, Text, Select } from "@chakra-ui/react";
import { useSearchNocs } from "../../api";
import { Wrapper } from "../../components/Wrapper";
import Autocomplete from "./../../components/Autocomplete";
import NocListItem from "./NocListItem";
import { IoInformation } from "react-icons/io5";
import { Tooltip } from "@chakra-ui/react"
import { InfoIcon } from '@chakra-ui/icons'

import Pagination from "./../../components/Pagination";
import { useLocation, useHistory } from "react-router-dom";

const OccupationPage = () => {
   const location = useLocation();
   const history = useHistory();

   const [searchText, setSearchText] = useState("");
   const [category, setCategory] = useState("all");

   const { data: nocs, isFetching: isFetchingNocs } = useSearchNocs(searchText);
   const { data: searchNocs, isFetching: isFetchingSearchNocs } = useSearchNocs("");

   useEffect(() => {
      // Pull search text from the url and set it in state
      setSearchText(location.search.slice(8, -1).replaceAll("%20", " "));
   }, [location.search]);

   const [numItemsToDisplay, setNumItemsToDisplay] = useState(50);
   const [selectedPage, setSelectedPage] = useState(1);

   let startingIndex = (selectedPage - 1) * numItemsToDisplay;

   let filteredNocs = [];

   const handleNocSearch = (text) => {
      // Place the searched text in the url
      history.push("?search=" + text);
      setSelectedPage(1)
   };

   // If the data is fetched then filter based on the selected category
   if (!isFetchingNocs) {
      if (category !== "all") {
         filteredNocs = [...nocs].filter(noc => noc.NOC[0] === category);
      } else {
         filteredNocs = [...nocs]
      }
   }

   // Performing pagination simply by slicing the large data set that is queried, could be improved serverside
   const nocsToDisplay = !isFetchingNocs ? [...filteredNocs].slice(startingIndex, startingIndex + numItemsToDisplay) : [];
   let numPages = !isFetchingNocs ? Math.ceil(filteredNocs.length / numItemsToDisplay) : 0;

   return (
      <>
         <Grid py={8} px={8} gap={10} width='100%'>
            <Grid gap={4} width="100%">
               <Heading size='lg' justifySelf='center' mr="2">
                  Employer Tools
               </Heading>
               <Text textAlign="center" fontSize='15px' overflow='wrap'>To attract the right talent, it is critical to include accurate skills required in job postings. This tool provides main duties and skills required to align job postings to best practices.</Text>
               <Autocomplete
                  handleSubmit={handleNocSearch}
                  show
                  inputLabel="downshift-1-input"
                  items={searchNocs ? searchNocs : []}
                  itemToString={(item) => item.Title}
                  button={{colorScheme: "facebook" , text: "Search" }}
                  placeholder='Type an occupation to find out the main duties, job titles and required skills.'
                  border="1px solid"
                  borderColor="blue.400"
               />
            </Grid>
            <Grid>
               <Grid py={4} gap={6} alignItems='center' templateColumns={{ base: "1fr", md: "max-content 1fr",lg: "repeat(3, max-content)" }}>
                  <Pagination numPages={numPages} setSelectedPage={setSelectedPage} selectedPage={selectedPage}/>

                  <Grid templateColumns="max-content 1fr" alignItems="center" columnGap={2}>
                     <Text as='span'>Category:</Text>
                     <Select
                        value={numItemsToDisplay.toString()}
                        colorScheme='blue'
                        width={{base: '15rem', lg:'18rem'}}
                        onChange={(e) => {
                           setCategory(e.target.value)
                           setSelectedPage(1)
                        }}
                        value={category}
                     >
                        <option value="all">All</option>
                        <option value="0">Management Occupations</option>
                        <option value="1">Business, finance and administration occupations</option>
                        <option value="2">Natural and applied sciences and related occupations</option>
                        <option value="3">Health occupations</option>
                        <option value="4">Occupations in education, law and social, community and government services</option>
                        <option value="5">Occupations in art, culture, recreation and sport</option>
                        <option value="6">Sales and service occupations</option>
                        <option value="7">Trades, transport and equipment operators and related occupations</option>
                        <option value="8">Natural resources, agriculture and related production occupations</option>
                        <option value="9">Occupations in manufacturing and utilities</option>
                     </Select>
                  </Grid>

                  <Grid templateColumns="max-content 1fr" alignItems="center" columnGap={2}>
                     <Text as='span'>Items Per Page:</Text>
                     <Select
                        value={numItemsToDisplay.toString()}
                        colorScheme='blue'
                        w="8rem"
                        onChange={(e) => {
                           let numItems = +e.target.value;
                           setSelectedPage(Math.floor(startingIndex / numItems) + 1);
                           setNumItemsToDisplay(numItems);
                        }}
                     >
                        <option value='10'>10</option>
                        <option value='20'>20</option>
                        <option value='50'>50</option>
                     </Select>
                  </Grid>
               </Grid>
               <Grid gap={8}>
                  {isFetchingNocs ? (

                        <Box height='70vh'>
                           <Spinner style={{float:"left"}}/>
                        <div style={{paddingLeft:"30px"}} >Loading Occupations</div>
                        </Box>

                        ) : (
                     nocsToDisplay.map((noc, index) => <NocListItem noc={noc} key={index} isInList />)
                  )}
               </Grid>
            </Grid>
            </Grid>
      </>
         );
};

         export default OccupationPage;
